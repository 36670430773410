import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_FORM = '@form/set-form';
export const GET_FORM = '@form/get-form';
export const GET_FORM_SUCCESS = '@form/get-form-success';
export const GET_FORM_ERROR = '@form/get-form-error';

export const MOVE_FORM_LINE = '@form/move-form-line';
export const MOVE_FORM_LINE_SUCCESS = '@form/move-form-line-success';
export const MOVE_FORM_LINE_ERROR = '@form/move-form-line-error';

export const SET_SELECTED_FORM = '@form/set-selected-form';

export const SET_BACK_TO_FORM_LINES_LIST = '@form/set-back-to-form-lines-list';

export const SET_FORM_PARTY = '@form/set-form-party';

export const SET_FORM_LINES = '@form/set-form-lines';

export const VERIFY_CODE = '@form/verify-code';
export const VERIFY_CODE_SUCCESS = '@form/verify-code-success';
export const VERIFY_CODE_ERROR = '@form/verify-code-error';

export const APPLY_SHARE_CODE = '@form/apply-share-code';
export const APPLY_SHARE_CODE_SUCCESS = '@form/apply-share-code-success';
export const APPLY_SHARE_CODE_ERROR = '@form/apply-share-code-error';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@form/set-visible-modal-form-number';

export const SET_VALUES_FORM_APPLY_SHARE_CODE = '@form/set-values-form-apply-share-code';

export const getForm = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FORM,
    });
    const { formPartyJoin } = getState();
    const {
      partyId,
      formId,
    } = formPartyJoin || {};
    const params = {
      partyId,
      formId,
    };
    const response = await axios.post('/api/formPartyJoin', { params });
    dispatch({
      type: GET_FORM_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_FORM_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_ERROR,
    });
  }
};

export const moveFormLineUpDown = (formId, formLineId, upDown) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MOVE_FORM_LINE,
    });
    const {
      autoMoveFormRows,
    } = getState()?.formPartyJoin || {};
    const params = {
      formId,
      formLineId,
      upDown,
      autoMoveFormRows,
    };
    const response = await axios.post('/api/formPartyJoin/moveLineUpDown', { params });

    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(MOVE_FORM_LINE_ERROR, 'Can not move form line', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: MOVE_FORM_LINE_ERROR,
      });
      return;
    }

    dispatch({
      type: MOVE_FORM_LINE_SUCCESS,
      payload: response?.data || {}
    });
    await dispatch(getForm());
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(MOVE_FORM_LINE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: MOVE_FORM_LINE_ERROR,
    });
  }
};

export const verifyCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VERIFY_CODE,
    });
    const {
      formApplyShareCode,
    } = getState().formPartyJoin || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const response = await axios.post('/api/formDefinition/verifyShareCode', { shareCode });
    const {
      formDefinitionName,
    } = response?.data || {};

    if (!formDefinitionName) {
      dispatch(addNotification(VERIFY_CODE_ERROR, 'Could Not Verify Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: VERIFY_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(VERIFY_CODE_SUCCESS, 'Valid Share Code - You Can Apply Now', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: VERIFY_CODE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(VERIFY_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: VERIFY_CODE_ERROR,
    });
  }
};

export const applyShareCode = (doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLY_SHARE_CODE,
    });
    const {
      partyId,
      formApplyShareCode
    } = getState().formPartyJoin || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const params = {
      partyId,
      shareCode,
    };
    const response = await axios.post('/api/formPartyJoin/applyShareCode', { params });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(APPLY_SHARE_CODE_ERROR, 'Could Not (Fully) Apply Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: APPLY_SHARE_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(APPLY_SHARE_CODE_SUCCESS, 'Successfully Applied the Share Code', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: APPLY_SHARE_CODE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(APPLY_SHARE_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: APPLY_SHARE_CODE_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setForm = (form) => ({
  type: SET_FORM,
  payload: form
});

export const setBackToFormLinesList = (payload) => ({
  type: SET_BACK_TO_FORM_LINES_LIST,
  payload,
});

export const clearBackToFormLinesList = (clearAll = false) => ({
  type: SET_BACK_TO_FORM_LINES_LIST,
  payload: !clearAll ? {
    form: [],
  } : false,
});

export const setFormParty = (payload) => ({
  type: SET_FORM_PARTY,
  payload,
});

export const setFormLines = (payload) => ({
  type: SET_FORM_LINES,
  payload,
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormApplyShareCode = (values) => ({
  type: SET_VALUES_FORM_APPLY_SHARE_CODE,
  payload: values
});
