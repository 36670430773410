/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_CURRENT_NAVIGATION_TAB,
  GET_PARTY,
  GET_PARTY_SUCCESS,
  GET_PARTY_ERROR,
  DELETE_PARTY,
  DELETE_PARTY_SUCCESS,
  DELETE_PARTY_ERROR, CLEAR_PARTY_WAS_DELETED,
  UPDATE_PARTY,
  UPDATE_PARTY_SUCCESS,
  UPDATE_PARTY_ERROR,
  CREATE_PARTY,
  CREATE_PARTY_SUCCESS,
  CREATE_PARTY_ERROR,
  UPDATE_PARTY_GUEST_MESSAGING,
  UPDATE_PARTY_GUEST_MESSAGING_SUCCESS,
  UPDATE_PARTY_GUEST_MESSAGING_ERROR,
  GET_PARTY_SOURCES,
  GET_PARTY_SOURCES_SUCCESS,
  GET_PARTY_SOURCES_ERROR,
  SET_PARTY_DETAILS_TAB,
  SET_FORM_CREATE_UPDATE_PARTY,
  SET_FORM_UPDATE_PARTY_GUEST_MESSAGING,
  SET_PARTY_GUESTS,
  GET_PARTY_GUESTS,
  GET_PARTY_GUESTS_SUCCESS,
  GET_PARTY_GUESTS_ERROR,
  SET_PARTY_GUESTS_QUERY,
  SET_SELECTED_PARTY_GUESTS,
  SET_PARTY_ORDERS,
  GET_PARTY_ORDERS,
  GET_PARTY_ORDERS_SUCCESS,
  GET_PARTY_ORDERS_ERROR,
  SET_PARTY_ORDERS_QUERY,
  SET_SELECTED_PARTY_ORDERS,
  SET_SELECTED_CONTACTS,
  SET_SELECTED_ORDERS,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_SEND_TEXT,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_ERROR,
  SEND_TEXT_MESSAGE_SUCCESS,
  SET_VALUES_FORM_ADD_PB_TAG,
  SEND_ADD_TAG_CAMPAIGN,
  SEND_ADD_TAG_CAMPAIGN_ERROR,
  SEND_ADD_TAG_CAMPAIGN_SUCCESS,
  SET_VALUES_FORM_ADD_PARTY_GUEST,
  SEND_ADD_PARTY_GUEST,
  SEND_ADD_PARTY_GUEST_ERROR,
  SEND_ADD_PARTY_GUEST_SUCCESS,
  SEND_REMOVE_PARTY_GUESTS,
  SEND_REMOVE_PARTY_GUESTS_ERROR,
  SEND_REMOVE_PARTY_GUESTS_SUCCESS,
  SET_VALUES_FORM_ADD_PARTY_ORDER,
  SEND_ADD_PARTY_ORDER,
  SEND_ADD_PARTY_ORDER_ERROR,
  SEND_ADD_PARTY_ORDER_SUCCESS,
  SEND_REMOVE_PARTY_ORDERS,
  SEND_REMOVE_PARTY_ORDERS_ERROR,
  SEND_REMOVE_PARTY_ORDERS_SUCCESS,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  GET_CONTACTS_LIST,
  GET_CONTACTS_LIST_SUCCESS,
  GET_CONTACTS_LIST_ERROR,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_ERROR,
  REPLACE_SYSTEM_GEN_MESSAGE,
  REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
  REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
  SET_VALUES_WELCOME_MESSAGE,
  GET_PARTY_TYPES,
  GET_PARTY_TYPES_SUCCESS,
  GET_PARTY_TYPES_ERROR,
  SET_UPDATE_UPDATE_SMART_ACTION_DATES,
  SET_UPDATE_UPDATE_PARTY_TYPE_COMPONENTS,
  SET_FORM_MODIFY_GUESTS,
  MODIFY_GUESTS,
  MODIFY_GUESTS_SUCCESS,
  MODIFY_GUESTS_ERROR,
} from 'src/actions/partyDetailsActions';

const initialCurrentNavigationTab = 'details';

const goToOrderDetails = {
  partyId: null,
  wasReturned: false,
  query: {},
};

const partySourcesList = {
  data: [],
  loading: false,
};

const formSendText = {
  systemGenMessageReplaced: false,
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const formAddPBTag = {
  loading: false,
};

const formAddPartyGuest = {
  loading: false,
  guest: '',
};

const formAddPartyOrder = {
  loading: false,
  order: '',
};

const formRemovePartyGuests = {
  loading: false,
};

const formRemovePartyOrders = {
  loading: false,
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const welcomeMessage = {
  systemGenMessageReplaced: false,
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const partyTypes = {
  partyTypeList: [],
  loading: false,
};

const formModifyGuests = {
  isVisible: false,
  loading: false,
  formData: {
    joinedPartyDateTime: '',
  },
};

const initialState = {
  party: {},
  selectedPartyGuests: [],
  selectedContacts: [],
  selectedPartyOrders: [],
  selectedOrders: [],
  query: {
    sort: {
      table2: 'contact',
      column1: 'lastName',
      column2: 'firstName',
      direction1: 'asc',
      direction2: 'asc',
    }
  },
  ordersQuery: {
    sort: {
      table2: 'orderHeader',
      column1: 'orderDateTime',
      column2: null,
      direction1: 'asc',
      direction2: null,
    }
  },
  partyWasDeleted: false,
  loading: false,
  // there is an issue with variable "loading". A lot of requests used one "loading", conflicted, and I couldn't move it to another object
  loadingPartyGuests: false,
  loadingParty: false,
  currentNavigationTab: initialCurrentNavigationTab,
  goToOrderDetails,
  formCreateUpdateParty: {},
  partyGuests: [],
  partyGuestsCount: 0,
  partyOrders: [],
  partyOrdersCount: 0,
  partySubtotal: 0,
  visibleModalFormNumber: null,
  partySourcesList,
  formSendText,
  formAddPBTag,
  formAddPartyGuest,
  formAddPartyOrder,
  formRemovePartyGuests,
  formRemovePartyOrders,
  welcomeMessage,
  followUpMessages,
  partyTypes,
  updateSmartActionDates: false,
  updatePartyTypeComponents: false,
  formModifyGuests,
};

const partyDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialCurrentNavigationTab;
      });
    }

    case SET_FORM_MODIFY_GUESTS: {
      return produce(state, (draft) => {
        draft.formModifyGuests.formData = payload ? {
          ...state.formModifyGuests.formData,
          ...payload,
        } : formModifyGuests.formData;
      });
    }

    case MODIFY_GUESTS: {
      return produce(state, (draft) => {
        draft.formModifyGuests.loading = true;
      });
    }

    case MODIFY_GUESTS_SUCCESS:
    case MODIFY_GUESTS_ERROR: {
      return produce(state, (draft) => {
        draft.formModifyGuests.loading = false;
      });
    }

    case GET_PARTY: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.loadingParty = true;
      });
    }

    case GET_PARTY_SUCCESS: {
      return produce(state, (draft) => {
        draft.party = payload?.party || {};
        draft.loading = false;
        draft.loadingParty = false;
      });
    }

    case GET_PARTY_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingParty = false;
      });
    }

    case GET_PARTY_SOURCES: {
      return produce(state, (draft) => {
        draft.partySourcesList.loading = true;
      });
    }

    case GET_PARTY_SOURCES_SUCCESS: {
      return produce(state, (draft) => {
        draft.partySourcesList.data = payload || [];
        draft.partySourcesList.loading = false;
      });
    }

    case GET_PARTY_SOURCES_ERROR: {
      return produce(state, (draft) => {
        draft.partySourcesList = { ...partySourcesList };
      });
    }

    case DELETE_PARTY: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_PARTY_SUCCESS: {
      return produce(state, (draft) => {
        draft.party = payload ? initialState.party : state.party;
        draft.loading = false;
        draft.partyWasDeleted = true;
      });
    }

    case DELETE_PARTY_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_PARTY_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.partyWasDeleted = false;
      });
    }

    case CREATE_PARTY:
    case UPDATE_PARTY: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_PARTY_SUCCESS:
    case CREATE_PARTY_ERROR:
    case UPDATE_PARTY_SUCCESS:
    case UPDATE_PARTY_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case UPDATE_PARTY_GUEST_MESSAGING: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case UPDATE_PARTY_GUEST_MESSAGING_SUCCESS:
    case UPDATE_PARTY_GUEST_MESSAGING_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_CREATE_UPDATE_PARTY: {
      return produce(state, (draft) => {
        draft.formCreateUpdateParty = payload ? {
          ...state.formCreateUpdateParty,
          ...payload,
        } : initialState.formCreateUpdateParty;
      });
    }

    case SET_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.partyGuests = payload || [];
        draft.selectedPartyGuests = [];
        draft.selectedContacts = [];
      });
    }

    case GET_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.loadingPartyGuests = true;
      });
    }

    case GET_PARTY_GUESTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyGuests = payload?.partyGuests || {};
        draft.partyGuestsCount = payload?.count;
        draft.loading = false;
        draft.loadingPartyGuests = false;
      });
    }

    case GET_PARTY_GUESTS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingPartyGuests = false;
      });
    }

    case SET_PARTY_GUESTS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_SELECTED_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.selectedPartyGuests = payload || [];
      });
    }

    case SET_PARTY_ORDERS: {
      return produce(state, (draft) => {
        draft.partyOrders = payload || [];
        draft.selectedPartyOrders = [];
        draft.selectedOrders = [];
      });
    }

    case GET_PARTY_ORDERS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_ORDERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyOrders = payload?.partyOrders || {};
        draft.partyOrdersCount = payload?.count;
        draft.partySubtotal = payload?.subtotal;
        draft.loading = false;
      });
    }

    case GET_PARTY_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_ORDERS_QUERY: {
      return produce(state, (draft) => {
        draft.ordersQuery = payload ? {
          ...state.ordersQuery,
          ...payload
        } : initialState.ordersQuery;
      });
    }

    case SET_SELECTED_PARTY_ORDERS: {
      return produce(state, (draft) => {
        draft.selectedPartyOrders = payload || [];
      });
    }

    case SET_SELECTED_CONTACTS: {
      return produce(state, (draft) => {
        draft.selectedContacts = payload || [];
      });
    }

    case SET_SELECTED_ORDERS: {
      return produce(state, (draft) => {
        draft.selectedOrders = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_SEND_TEXT: {
      return produce(state, (draft) => {
        draft.formSendText = payload ? {
          ...state.formSendText,
          ...payload,
        } : formSendText;
      });
    }

    case SEND_TEXT_MESSAGE: {
      return produce(state, (draft) => {
        draft.formSendText.loading = true;
      });
    }

    case SEND_TEXT_MESSAGE_SUCCESS:
    case SEND_TEXT_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.formSendText.loading = false;
      });
    }

    case SET_VALUES_FORM_ADD_PB_TAG: {
      return produce(state, (draft) => {
        draft.formAddPBTag = payload ? {
          ...state.formAddPBTag,
          ...payload,
        } : formAddPBTag;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = true;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN_SUCCESS:
    case SEND_ADD_TAG_CAMPAIGN_ERROR: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = false;
      });
    }

    case SET_VALUES_FORM_ADD_PARTY_GUEST: {
      return produce(state, (draft) => {
        draft.formAddPartyGuest = payload ? {
          ...state.formAddPartyGuest,
          ...payload,
        } : formAddPartyGuest;
      });
    }

    case SEND_ADD_PARTY_GUEST: {
      return produce(state, (draft) => {
        draft.formAddPartyGuest.loading = true;
      });
    }

    case SEND_ADD_PARTY_GUEST_SUCCESS:
    case SEND_ADD_PARTY_GUEST_ERROR: {
      return produce(state, (draft) => {
        draft.formAddPartyGuest.loading = false;
      });
    }

    case SEND_REMOVE_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.formRemovePartyGuests.loading = true;
      });
    }

    case SEND_REMOVE_PARTY_GUESTS_SUCCESS:
    case SEND_REMOVE_PARTY_GUESTS_ERROR: {
      return produce(state, (draft) => {
        draft.formRemovePartyGuests.loading = false;
      });
    }

    case SET_VALUES_FORM_ADD_PARTY_ORDER: {
      return produce(state, (draft) => {
        draft.formAddPartyOrder = payload ? {
          ...state.formAddPartyOrder,
          ...payload,
        } : formAddPartyOrder;
      });
    }

    case SEND_ADD_PARTY_ORDER: {
      return produce(state, (draft) => {
        draft.formAddPartyOrder.loading = true;
      });
    }

    case SEND_ADD_PARTY_ORDER_SUCCESS:
    case SEND_ADD_PARTY_ORDER_ERROR: {
      return produce(state, (draft) => {
        draft.formAddPartyOrder.loading = false;
      });
    }

    case SEND_REMOVE_PARTY_ORDERS: {
      return produce(state, (draft) => {
        draft.formRemovePartyOrders.loading = true;
      });
    }

    case SEND_REMOVE_PARTY_ORDERS_SUCCESS:
    case SEND_REMOVE_PARTY_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.formRemovePartyOrders.loading = false;
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case GET_CONTACTS_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_CONTACTS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.contacts = payload || {};
        draft.loading = false;
      });
    }

    case GET_CONTACTS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case GET_ORDERS_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ORDERS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.orders = payload || {};
        draft.loading = false;
      });
    }

    case GET_ORDERS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE: {
      return produce(state, (draft) => {
        draft.welcomeMessage.loading = true;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS:
    case REPLACE_SYSTEM_GEN_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.welcomeMessage.loading = false;
        draft.welcomeMessage.systemGenMessageReplaced = payload || false;
      });
    }

    case SET_FORM_UPDATE_PARTY_GUEST_MESSAGING: {
      return produce(state, (draft) => {
        draft.formPartyGuestMessaging = payload ? {
          ...state.formPartyGuestMessaging,
          ...payload,
        } : initialState.formPartyGuestMessaging;
      });
    }

    case SET_VALUES_WELCOME_MESSAGE: {
      return produce(state, (draft) => {
        draft.welcomeMessage = payload ? {
          ...state.welcomeMessage,
          ...payload,
        } : welcomeMessage;
      });
    }

    case GET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.partyTypes.loading = true;
      });
    }

    case GET_PARTY_TYPES_SUCCESS:
    case GET_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.partyTypes.loading = false;
        draft.partyTypes.partyTypeList = payload ? [
          ...state.partyTypes.partyTypeList,
          ...payload,
        ] : partyTypes.partyTypeList;
      });
    }

    case SET_UPDATE_UPDATE_SMART_ACTION_DATES: {
      return produce(state, (draft) => {
        draft.updateSmartActionDates = payload;
      });
    }

    case SET_UPDATE_UPDATE_PARTY_TYPE_COMPONENTS: {
      return produce(state, (draft) => {
        draft.updatePartyTypeComponents = payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default partyDetailsReducer;
