import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_SMART_ACTION_QUERY = '@smartactions/set-smart-action-query';
export const CLEAR_SMART_ACTIONS_QUERY_FILTER = '@smartactions/clear-smart-actions-query-filter';

export const SET_SMART_ACTIONS = '@smartactions/set-smart-actions';
export const GET_SMART_ACTIONS = '@smartactions/get-smart-actions';
export const GET_SMART_ACTIONS_SUCCESS = '@smartactions/get-smart-actions-success';
export const GET_SMART_ACTIONS_ERROR = '@smartactions/get-smart-actions-error';

export const VERIFY_CODE = '@smartactions/verify-code';
export const VERIFY_CODE_SUCCESS = '@smartactions/verify-code-success';
export const VERIFY_CODE_ERROR = '@smartactions/verify-code-error';

export const APPLY_SHARE_CODE = '@smartactions/apply-share-code';
export const APPLY_SHARE_CODE_SUCCESS = '@smartactions/apply-share-code-success';
export const APPLY_SHARE_CODE_ERROR = '@smartactions/apply-share-code-error';

export const ADJUST_DAYS_HOURS = '@smartactions/adjust-days-hours';
export const ADJUST_DAYS_HOURS_SUCCESS = '@smartactions/adjust-days-hours-success';
export const ADJUST_DAYS_HOURS_ERROR = '@smartactions/adjust-days-hours-error';

export const RESET_ACTIONS = '@smartactions/reset-actions';
export const RESET_ACTIONS_SUCCESS = '@smartactions/reset-actions-success';
export const RESET_ACTIONS_ERROR = '@smartactions/reset-actions-error';

export const SKIP_UNSKIP_ACTIONS = '@smartactions/skip-unskip-actions';
export const SKIP_UNSKIP_ACTIONS_SUCCESS = '@smartactions/skip-unskip-actions-success';
export const SKIP_UNSKIP_ACTIONS_ERROR = '@smartactions/skip-unskip-actions-error';

export const DELETE_ACTIONS = '@smartactions/delete-actions';
export const DELETE_ACTIONS_SUCCESS = '@smartactions/delete-actions-success';
export const DELETE_ACTIONS_ERROR = '@smartactions/delete-actions-error';

export const SET_SMART_ACTIONS_QUERY = '@smartactions/set-smart-actions-query';

export const SET_SELECTED_SMART_ACTIONS = '@smartactions/set-selected-smart-actions';

export const SET_BACK_TO_SMART_ACTION_LIST = '@smartactions/set-back-to-smart-action-list';

export const SET_SMART_ACTIONS_PARTY = '@smartactions/set-smart-actions-party';

export const SET_SMART_ACTIONS_GROUP_ID = '@smartactions/set-smart-actions-group-id';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@smartactions/set-visible-modal-form-number';

export const SET_VALUES_FORM_APPLY_SHARE_CODE = '@smartactions/set-values-form-apply-share-code';

export const SET_VALUES_FORM_ADJUST_DAYS_HOURS = '@smartactions/set-values-form-adjust-days-hours';

export const getSmartActions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SMART_ACTIONS,
    });
    const smartActions = getState().scheduledSmartActions;
    const {
      partyId,
      smartActionGroupId,
      query,
    } = smartActions || {};
    const params = {
      partyId,
      scheduledSmartActionGroupId: smartActionGroupId,
      query,
    };
    const response = await axios.post('/api/scheduledSmartActions', { params });
    dispatch({
      type: GET_SMART_ACTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_SMART_ACTIONS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_SMART_ACTIONS_ERROR,
    });
  }
};

export const verifyCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VERIFY_CODE,
    });
    const {
      formApplyShareCode
    } = getState().smartActions || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const response = await axios.post('/api/smartActions/verifyShareCode', { shareCode });
    const {
      smartActionName,
    } = response?.data || {};

    if (!smartActionName) {
      dispatch(addNotification(VERIFY_CODE_ERROR, 'Could Not Verify Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: VERIFY_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(VERIFY_CODE_SUCCESS, 'Valid Share Code - You Can Apply Now', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: VERIFY_CODE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(VERIFY_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: VERIFY_CODE_ERROR,
    });
  }
};

export const applyShareCode = (doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLY_SHARE_CODE,
    });
    const {
      partyId,
      formApplyShareCode
    } = getState().scheduledSmartActions || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const params = {
      partyId,
      shareCode,
    };
    const response = await axios.post('/api/scheduledSmartActions/applyShareCode', { params });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(APPLY_SHARE_CODE_ERROR, 'Could Not (Fully) Apply Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: APPLY_SHARE_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(APPLY_SHARE_CODE_SUCCESS, 'Successfully Applied the Share Code', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: APPLY_SHARE_CODE_SUCCESS,
      // payload: response?.data || {}
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(APPLY_SHARE_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: APPLY_SHARE_CODE_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const adjustDaysHours = (doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADJUST_DAYS_HOURS,
    });
    const {
      formAdjustDaysHours,
      selectedSmartActions,
    } = getState().scheduledSmartActions || {};
    const {
      numberDaysToAdjust,
      numberHoursToAdjust,
    } = formAdjustDaysHours || {};
    const params = {
      numberDaysToAdjust,
      numberHoursToAdjust,
      selectedSmartActions,
    };
    const response = await axios.post('/api/scheduledSmartActions/adjustDaysHours', { params });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(ADJUST_DAYS_HOURS_ERROR, 'Could Not (Fully) Adjust the Days/Hours', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: ADJUST_DAYS_HOURS_ERROR,
      });
      return;
    }

    dispatch(addNotification(ADJUST_DAYS_HOURS_SUCCESS, 'Successfully Adjusted the Days/Hours', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: ADJUST_DAYS_HOURS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(ADJUST_DAYS_HOURS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: ADJUST_DAYS_HOURS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const resetSmartActions = (selectedSmartActions, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ACTIONS,
    });

    const response = await axios.put('/api/scheduledSmartActions/resetSmartActions', { selectedSmartActions });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(RESET_ACTIONS_ERROR, 'Could Not (Fully) Reset Smart Actions', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: RESET_ACTIONS_ERROR,
      });
      return;
    }

    dispatch(addNotification(RESET_ACTIONS_SUCCESS, 'Successfully Reset Smart Actions', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: RESET_ACTIONS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(RESET_ACTIONS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: RESET_ACTIONS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const skipUnskipSmartActions = (selectedSmartActions, skip, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SKIP_UNSKIP_ACTIONS,
    });

    const response = await axios.put('/api/scheduledSmartActions/skipUnskipSmartActions', { selectedSmartActions, skip });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SKIP_UNSKIP_ACTIONS_ERROR, skip ? 'Could Not (Fully) Skip Actions' : 'Could Not (Fully) Unskip Actions', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SKIP_UNSKIP_ACTIONS_ERROR,
      });
      return;
    }

    dispatch(addNotification(SKIP_UNSKIP_ACTIONS_SUCCESS, skip ? 'Successfully Skipped Actions' : 'Successfully Unskipped Actions', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: SKIP_UNSKIP_ACTIONS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SKIP_UNSKIP_ACTIONS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SKIP_UNSKIP_ACTIONS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const deleteSmartActions = (selectedSmartActions, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ACTIONS,
    });

    const response = await axios.put('/api/scheduledSmartActions/deleteSmartActions', { selectedSmartActions });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(DELETE_ACTIONS_ERROR, 'Could Not Delete All Smart Actions', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: DELETE_ACTIONS_ERROR,
      });
      return;
    }

    dispatch(addNotification(DELETE_ACTIONS_SUCCESS, 'Successfully Deleted Smart Action(s)', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: DELETE_ACTIONS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(DELETE_ACTIONS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: DELETE_ACTIONS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setSmartActions = (smartActions) => ({
  type: SET_SMART_ACTIONS,
  payload: smartActions
});

export const setSmartActionsQuery = (query) => ({
  type: SET_SMART_ACTIONS_QUERY,
  payload: query
});

export const clearSmartActionsQueryFilter = () => ({
  type: CLEAR_SMART_ACTIONS_QUERY_FILTER,
});

export const setSelectedSmartActions = (selectedSmartActions) => ({
  type: SET_SELECTED_SMART_ACTIONS,
  payload: selectedSmartActions
});

export const setBackToSmartActionList = (payload) => ({
  type: SET_BACK_TO_SMART_ACTION_LIST,
  payload,
});

export const clearBackToSmartActionList = (clearAll = false) => ({
  type: SET_BACK_TO_SMART_ACTION_LIST,
  payload: !clearAll ? {
    smartActions: [],
    query: {},
  } : false,
});

export const setSmartActionsParty = (payload) => ({
  type: SET_SMART_ACTIONS_PARTY,
  payload,
});

export const setSmartActionsGroupId = (groupId) => ({
  type: SET_SMART_ACTIONS_GROUP_ID,
  payload: groupId,
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormApplyShareCode = (values) => ({
  type: SET_VALUES_FORM_APPLY_SHARE_CODE,
  payload: values
});

export const setValuesFormAdjustDaysHours = (values) => ({
  type: SET_VALUES_FORM_ADJUST_DAYS_HOURS,
  payload: values
});
