import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Tooltip from 'src/components/Tooltip/index';

import { ReactComponent as CopyIcon } from 'src/assets/images/copy-icon.svg';
import copyTextStyle from 'src/components/CopyText/copyText.style';

function CopyText({
  classes,
  text,
  valueToCopy,
  tooltipCopyText,
  tooltipCopiedText,
  showIfNoText,
}) {
  const [isTextCopied, setIsTextCopied] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClickCopyButton = (e) => {
    navigator.clipboard.writeText(!valueToCopy ? text : valueToCopy);
    setIsTextCopied(true);
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
      setTimeout(() => {
        setIsTextCopied(false);
      }, 200);
    }, 2500);
    e.stopPropagation();
  };

  const handleMouseLeave = () => {
    if (!isTextCopied) {
      setOpenTooltip(false);
    }
  };

  const handleMouseEnter = () => {
    setOpenTooltip(true);
  };

  return text || showIfNoText ? (
    navigator.clipboard ? (
      <Tooltip
        title={!isTextCopied ? tooltipCopyText : tooltipCopiedText}
        open={openTooltip}
      >
        <Button
          className={classes.root}
          onClick={handleClickCopyButton}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          {text || ''}
          <SvgIcon
            className="svgIcon"
            component={CopyIcon}
            viewBox="0 0 448 512"
          />
        </Button>
      </Tooltip>
    ) : text || ''
  ) : null;
}

CopyText.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  valueToCopy: PropTypes.string,
  tooltipCopyText: PropTypes.string,
  tooltipCopiedText: PropTypes.string,
  showIfNoText: PropTypes.bool,
};
CopyText.defaultProps = {
  text: null,
  valueToCopy: null,
  tooltipCopyText: 'Click to copy',
  tooltipCopiedText: 'Copied',
  showIfNoText: true,
};

export default withStyles(CopyText, copyTextStyle);
