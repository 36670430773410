import React from 'react';
import PropTypes from 'prop-types';

function PSUpdateStatementText({ classes }) {
  return (
    <p className={classes.text}>
      The Privacy Policy has changed.
      <br />
      Please review and agree to the updated
      {' '}
      <a
        target="_blank"
        href="https://drive.google.com/file/d/1-4JKQNnyGQrAT_J_8G5Nbuups4rVqyOR"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      {' '}
      to continue.
    </p>
  );
}

PSUpdateStatementText.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PSUpdateStatementText;
