import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { STYLE_OPTIONS } from 'src/constants';

function TooltipMUI6({
  children,
  ...props
}) {
  return (
    <Tooltip
      enterDelay={50}
      enterTouchDelay={50}
      leaveTouchDelay={2500}
      slotProps={{
        tooltip: {
          sx: {
            fontFamily: STYLE_OPTIONS.FONT_FAMILY,
            color: '#000000',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.4px',
            fontWeight: 400,
            backgroundColor: '#F9F9FE',
            border: '1px solid #5850EC',
            padding: '17px 15px',
            whiteSpace: 'pre-line',
            borderRadius: '20px',
            textShadow: '0px 0px #000000',
            '& .MuiTooltip-arrow': {
              color: '#F9F9FE',
              '&:before': {
                border: '1px solid #5850EC',
              }
            },
            '& .nbsp': {
              display: 'inline-block',
              width: '0.3em',
            },
          }
        }
      }}
      arrow
      {...props}
    >
      {children}
    </Tooltip>
  );
}

TooltipMUI6.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.any,
};

TooltipMUI6.defaultProps = {
  title: '',
  children: null,
};

export default TooltipMUI6;
