/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION,
  SAVE_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION,
  MOVE_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION,
  CLEAR_MOVING_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION,
  SET_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION,
  SAVE_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION,
  MOVE_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION,
  CLEAR_MOVING_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION,
} from 'src/actions/scrollActions';

const initialState = {
  currentScrollDashboardLayoutContentPosition: {
    x: 0,
    y: 0,
    shouldSave: false,
  },
  currentScrollPerfectScrollbarTablePosition: {
    x: 0,
    y: 0,
    shouldSave: false,
  },
  moveScrollDashboardLayoutContentToPosition: {
    x: 0,
    y: 0,
    shouldStart: false,
  },
  moveScrollPerfectScrollbarTableToPosition: {
    x: 0,
    y: 0,
    shouldStart: false,
    perfectScrollbarId: 'scrollBox',
  },
};

const scrollReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION: {
      return produce(state, (draft) => {
        draft.currentScrollDashboardLayoutContentPosition = {
          ...state.currentScrollDashboardLayoutContentPosition,
          x: payload?.x || 0,
          y: payload?.y || 0,
          shouldSave: false,
        };
      });
    }

    case SAVE_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION: {
      return produce(state, (draft) => {
        draft.currentScrollDashboardLayoutContentPosition.shouldSave = !!payload;
      });
    }

    case MOVE_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION: {
      return produce(state, (draft) => {
        draft.moveScrollDashboardLayoutContentToPosition = {
          ...state.moveScrollDashboardLayoutContentToPosition,
          x: payload?.x || 0,
          y: payload?.y || 0,
          shouldStart: true,
        };
      });
    }

    case CLEAR_MOVING_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION: {
      return produce(state, (draft) => {
        draft.moveScrollDashboardLayoutContentToPosition = {
          ...initialState.moveScrollDashboardLayoutContentToPosition,
        };
      });
    }

    case SET_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION: {
      return produce(state, (draft) => {
        draft.currentScrollPerfectScrollbarTablePosition = {
          ...state.currentScrollPerfectScrollbarTablePosition,
          x: payload?.x || 0,
          y: payload?.y || 0,
          shouldSave: false,
        };
      });
    }

    case SAVE_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION: {
      return produce(state, (draft) => {
        draft.currentScrollPerfectScrollbarTablePosition.shouldSave = !!payload;
      });
    }

    case MOVE_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION: {
      return produce(state, (draft) => {
        draft.moveScrollPerfectScrollbarTableToPosition = {
          ...state.moveScrollPerfectScrollbarTableToPosition,
          x: payload?.x || 0,
          y: payload?.y || 0,
          shouldStart: true,
          ...payload?.perfectScrollbarId ? { perfectScrollbarId: payload.perfectScrollbarId } : {},
        };
      });
    }

    case CLEAR_MOVING_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION: {
      return produce(state, (draft) => {
        draft.moveScrollPerfectScrollbarTableToPosition = {
          ...initialState.moveScrollPerfectScrollbarTableToPosition,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default scrollReducer;
