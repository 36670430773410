import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { setCurrentNavigationTab, refreshCustomFields } from 'src/actions/accountSettingsActions';
import helpers from 'src/helpers';
import { needOtherInfoNow, needCustomFieldsNow } from './helpers/check';

function RedirectLogic({ defaultRedirect }) {
  const dispatch = useDispatch();
  const {
    user,
    pbAPIKeyValid,
  } = useSelector((state) => state.account);
  const { currentNavigationTab } = useSelector((state) => state.accountSettings);

  if (helpers.isEmpty(user)) {
    return null;
  }

  const doesUserHaveSubscription = helpers.doesUserHaveSubscription(user);
  const isAdmin = helpers.isUserAdmin(user);

  const canShowHelpAndInformation = !helpers.isEmptyApiKey(user) && window.location.pathname.indexOf(ROUTES.APP_HELP_AND_INFORMATION) === 0;
  const currentIsRequiredTab = currentNavigationTab === 'required' && window.location.pathname.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (helpers.isPageBlocked(user) && !currentIsRequiredTab && !canShowHelpAndInformation) {
    dispatch(setCurrentNavigationTab(false));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  const currentIsOtherTab = currentNavigationTab === 'other' && window.location.pathname.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (needOtherInfoNow(user) && !currentIsOtherTab && !canShowHelpAndInformation) {
    dispatch(setCurrentNavigationTab('other'));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  const currentIsSubscriptionTab = currentNavigationTab === 'subscription' && window.location.pathname.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (!isAdmin && !helpers.isPageBlocked(user) && !doesUserHaveSubscription && !currentIsSubscriptionTab && !needOtherInfoNow(user) && !needCustomFieldsNow(user, pbAPIKeyValid)) {
    dispatch(setCurrentNavigationTab('subscription'));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  const currentIsCustomFieldsTab = currentNavigationTab === 'customFields' && window.location.pathname.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (needCustomFieldsNow(user, pbAPIKeyValid) && !currentIsCustomFieldsTab && !canShowHelpAndInformation) {
    dispatch(refreshCustomFields(user.id));
    if (needCustomFieldsNow(user, pbAPIKeyValid)) {
      dispatch(setCurrentNavigationTab('customFields'));
      return <Redirect to={ROUTES.APP_ACCOUNT} />;
    }
  }

  if (defaultRedirect) {
    return <Redirect to={defaultRedirect} />;
  }

  return null;
}

RedirectLogic.propTypes = {
  defaultRedirect: PropTypes.string,
};
RedirectLogic.defaultProps = {
  defaultRedirect: null,
};

export default RedirectLogic;
