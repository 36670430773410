import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { NOTIFICATION_STATUS } from 'src/constants';
import helpers from 'src/helpers';

export const SET_PARTY_TYPES = '@partytypes/set-party-types';
export const GET_PARTY_TYPES = '@partytypes/get-party-types';
export const GET_PARTY_TYPES_SUCCESS = '@partytypes/get-party-types-success';
export const GET_PARTY_TYPES_ERROR = '@partytypes/get-party-types-error';

export const SET_PARTY_TYPES_QUERY = '@partytypes/set-party-types-query';
export const SET_BACK_TO_PARTY_TYPES_LIST = '@partytypes/set-back-to-party-types-list';

export const VERIFY_CODE = '@partytypes/verify-code';
export const VERIFY_CODE_SUCCESS = '@partytypes/verify-code-success';
export const VERIFY_CODE_ERROR = '@partytypes/verify-code-error';

export const APPLY_SHARE_CODE = '@partytypes/apply-share-code';
export const APPLY_SHARE_CODE_SUCCESS = '@partytypes/apply-share-code-success';
export const APPLY_SHARE_CODE_ERROR = '@partytypes/apply-share-code-error';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@partytypes/set-visible-modal-form-number';

export const SET_VALUES_FORM_APPLY_SHARE_CODE = '@partytypes/set-values-form-apply-share-code';

export const SET_IS_MODAL_LOADING = '@partytypes/set-modal-loading';
export const SET_IS_MODAL_VISIBLE = '@partytypes/set-modal-visible';
export const SET_MODAL_MESSAGE = '@partytypes/set-modal-message';

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const setModalMessage = (isVisible, modalTitle, modalBody) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    isVisible,
    modalTitle,
    modalBody,
  },
});
export const getPartyTypes = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_TYPES,
  });
  try {
    const response = await axios.post('/api/partySettings/partyTypes', getState().partyTypes.query);
    dispatch({
      type: GET_PARTY_TYPES_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_TYPES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_TYPES_ERROR,
    });
  }
};

export const verifyCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VERIFY_CODE,
    });
    const {
      formApplyShareCode,
    } = getState().partyTypes || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const response = await axios.post('/api/partySettings/partyTypes/verifyShareCode', { shareCode });
    const {
      partyTypeName,
    } = response?.data || {};

    if (!partyTypeName) {
      dispatch(addNotification(VERIFY_CODE_ERROR, 'Could Not Verify Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: VERIFY_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(VERIFY_CODE_SUCCESS, 'Valid Share Code - You Can Apply Now', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: VERIFY_CODE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(VERIFY_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: VERIFY_CODE_ERROR,
    });
  }
};

export const applyShareCode = (doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLY_SHARE_CODE,
    });
    const {
      partyId,
      formApplyShareCode
    } = getState().partyTypes || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const params = {
      partyId,
      shareCode,
    };
    const response = await axios.post('/api/partySettings/partyTypes/applyShareCode', { params });
    const {
      // success,
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(APPLY_SHARE_CODE_ERROR, 'Can not create party type', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: APPLY_SHARE_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(APPLY_SHARE_CODE_SUCCESS, 'Your party type has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: APPLY_SHARE_CODE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      modalTitle,
      modalBody,
      message,
      error,
    } = e?.response?.data || {};
    if (modalBody) {
      // 2024-07-01 - This comes from partyTypeDetailsActions.updatePartyType
      dispatch(setModalMessage(true, modalTitle, modalBody));
    }
    const { message: errorMessage } = error || {};
    dispatch(addNotification(APPLY_SHARE_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: APPLY_SHARE_CODE_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setPartyTypes = (partyTypes) => ({
  type: SET_PARTY_TYPES,
  payload: partyTypes
});

export const setPartyTypesQuery = (query) => ({
  type: SET_PARTY_TYPES_QUERY,
  payload: query
});

export const setBackToPartyTypesList = (payload) => ({
  type: SET_BACK_TO_PARTY_TYPES_LIST,
  payload,
});

export const clearBackToPartyTypesList = (clearAll = false) => ({
  type: SET_BACK_TO_PARTY_TYPES_LIST,
  payload: !clearAll ? {
    partyTypes: [],
    query: {},
  } : false,
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormApplyShareCode = (values) => ({
  type: SET_VALUES_FORM_APPLY_SHARE_CODE,
  payload: values
});
