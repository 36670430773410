import React, { useEffect, useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListSubheader,
  SvgIcon,
  Typography,
} from '@mui/material';
import { withStyles, useStyles } from 'tss-react/mui';
import Logo from 'src/components/Logo';
import CopyText from 'src/components/CopyText/index';
import helpers from 'src/helpers';
import NavItemsParent from 'src/layouts/DashboardLayout/NavBar/NavItemsParent';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem';
import navConfig from 'src/layouts/DashboardLayout/NavBar/options';

import navBarStyle from 'src/layouts/DashboardLayout/NavBar/navBar.style';

function NavBar({
  classes, logout, isDesktopNavOpen, setDesktopNavOpen, openMobile, onMobileClose
}) {
  const location = useLocation();
  const {
    user,
    pbAPIKeyValid,
  } = useSelector((state) => state.account);
  const [isButtonShowSmallDesktopClick, setButtonShowSmallDesktopClick] = useState(true);
  const { cx } = useStyles();
  const {
    betaUser,
    userSubscription,
  } = user || {};
  const {
    referralCode,
  } = userSubscription || {};

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const isStuckOnSettings = helpers.isPageBlocked(user)
    || helpers.needOtherInfoNow(user)
    || helpers.needCustomFieldsNow(user, pbAPIKeyValid);
  const doesUserHaveSubscription = helpers.doesUserHaveSubscription(user);
  const isEmptyApiKey = helpers.isEmptyApiKey(user);

  const handleClickMenuOpen = () => {
    setDesktopNavOpen(isButtonShowSmallDesktopClick);
    setButtonShowSmallDesktopClick(!isButtonShowSmallDesktopClick);
  };

  const handleDrawerDesktopMouseEnter = () => {
    if (isButtonShowSmallDesktopClick) {
      setDesktopNavOpen(true);
    }
  };

  const handleDrawerDesktopMouseLeave = () => {
    if (isButtonShowSmallDesktopClick) {
      setDesktopNavOpen(false);
    }
  };

  const styleGreenIcon = {
    color: '#78DC78',
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box className="navBox" p={2}>
          <Box
            className="navBoxUserName"
            mt={2}
            textAlign="center"
          >
            <Hidden lgDown>
              <IconButton color="inherit" onClick={handleClickMenuOpen} size="large">
                <SvgIcon fontSize="small">
                  {isButtonShowSmallDesktopClick ? <MenuIcon /> : <MenuOpenIcon />}
                </SvgIcon>
              </IconButton>
            </Hidden>
            <div className="navBoxUserNameIn">
              <Link
                component={RouterLink}
                to="/app/account"
                variant="h5"
                color="textPrimary"
                underline="none"
              >
                {`${user.firstName} ${user.lastName}`}
              </Link>
              <Typography variant="body2" color="textSecondary">
                {user.bio}
              </Typography>
            </div>
          </Box>
        </Box>
        <Divider />
        <Box className="navBox" p={2}>
          {navConfig(logout).filter((config) => !config.betaOnly || betaUser) && navConfig(logout).filter((config) => !config.betaOnly || betaUser).map((config) => {
            const {
              subheader,
              isLink,
              href,
              icon,
              click,
              isShownWhenOtherHidden,
              betaOnly,
            } = config;
            return (
              <List
                key={subheader}
                subheader={(
                  <ListSubheader
                    className="navSubHead"
                    disableGutters
                    disableSticky
                  >
                    {isLink && (!betaOnly || betaUser) ? (
                      <ul className={classes.navSubHeadFirstItem}>
                        <NavItem
                          key={subheader}
                          depth={0}
                          icon={icon}
                          title={subheader}
                          isPageBlocked={isStuckOnSettings || isEmptyApiKey}
                          href={href}
                          click={click}
                          className="firstLine"
                          isShownWhenOtherHidden={isShownWhenOtherHidden}
                        />
                      </ul>
                    ) : subheader}
                  </ListSubheader>
                )}
                className={isLink ? 'firstLineLink' : ''}
              >
                <NavItemsParent
                  items={config.items}
                  pathname={location.pathname}
                  isPageBlocked={isStuckOnSettings || !doesUserHaveSubscription}
                  isBetaUser={betaUser}
                />
              </List>
            );
          })}
        </Box>
        <Divider />
        <Box className="navBox" p={2}>
          <List
            key="Your Referral Code"
            subheader={(
              <ListSubheader
                className="navSubHead"
                disableGutters
                disableSticky
              >
                Your Referral Code
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem className={cx(classes.itemLeaf)} disableGutters key={referralCode}>
                <AttachMoneyIcon
                  className={`${classes.icon} navItemIcon`}
                  size="20"
                  style={styleGreenIcon}
                />
                <span className={`${classes.title} navItemText`}>
                  <CopyText
                    text={referralCode}
                    tooltipCopyText="Click to Copy, share & earn a $2 CP Credit!"
                    tooltipCopiedText="Copied. Now Share!"
                  />
                </span>
              </ListItem>
            </List>
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{
            paper: cx({
              [classes.desktopDrawer]: true,
              [classes.desktopSmallDrawer]: !isDesktopNavOpen,
            })
          }}
          open
          variant="persistent"
          {...isButtonShowSmallDesktopClick ? {
            onMouseEnter: handleDrawerDesktopMouseEnter,
            onMouseLeave: handleDrawerDesktopMouseLeave,
          } : {}}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isDesktopNavOpen: PropTypes.bool.isRequired,
  setDesktopNavOpen: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default withStyles(NavBar, navBarStyle, { withTheme: true });
