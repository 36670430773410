import React from 'react';
import PropTypes from 'prop-types';

function TOSUpdateStatementText({ classes }) {
  return (
    <p className={classes.text}>
      The Terms of Service has changed.
      <br />
      Please review and agree to the updated
      {' '}
      <a
        target="_blank"
        href="https://drive.google.com/file/d/101Y30SYGunh3-Q2_itNKhnSzMqeHuj52"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      {' '}
      to continue.
    </p>
  );
}

TOSUpdateStatementText.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default TOSUpdateStatementText;
