export const SET_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION = '@scroll/set-current-scroll-dashboard-layout-content-position';
export const SAVE_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION = '@scroll/save-current-scroll-dashboard-layout-content-position';
export const MOVE_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION = '@scroll/move-scroll-dashboard-layout-content-to-position';
export const CLEAR_MOVING_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION = '@scroll/clear-moving-scroll-dashboard-layout-content-to-position';

export const SET_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION = '@scroll/set-current-scroll-perfect-scrollbar-table-position';
export const SAVE_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION = '@scroll/save-current-scroll-perfect-scrollbar-table-position';
export const MOVE_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION = '@scroll/move-scroll-perfect-scrollbar-table-to-position';
export const CLEAR_MOVING_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION = '@scroll/clear-moving-scroll-perfect-scrollbar-table-to-position';

export const setCurrentScrollDashboardLayoutContentPosition = (x, y) => ({
  type: SET_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION,
  payload: {
    x,
    y,
  }
});

// call dispatch(saveCurrentScrollDashboardLayoutContentPosition(true));
// dispatch(saveCurrentScrollPerfectScrollbarTablePosition(true));
// if you want to save current position
export const saveCurrentScrollDashboardLayoutContentPosition = (payload) => ({
  type: SAVE_CURRENT_SCROLL_DASHBOARD_LAYOUT_CONTENT_POSITION,
  payload,
});

export const moveScrollDashboardLayoutContentToPosition = (x, y) => ({
  type: MOVE_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION,
  payload: {
    x,
    y,
  }
});

export const clearMovingScrollDashboardLayoutContentToPosition = () => ({
  type: CLEAR_MOVING_SCROLL_DASHBOARD_LAYOUT_CONTENT_TO_POSITION,
});

export const setCurrentScrollPerfectScrollbarTablePosition = (x, y) => ({
  type: SET_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION,
  payload: {
    x,
    y,
  }
});

export const saveCurrentScrollPerfectScrollbarTablePosition = (payload) => ({
  type: SAVE_CURRENT_SCROLL_PERFECT_SCROLLBAR_TABLE_POSITION,
  payload,
});

export const moveScrollPerfectScrollbarTableToPosition = (x, y, perfectScrollbarId = 'scrollBox') => ({
  type: MOVE_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION,
  payload: {
    x,
    y,
    perfectScrollbarId,
  }
});

export const clearMovingScrollPerfectScrollbarTableToPosition = () => ({
  type: CLEAR_MOVING_SCROLL_PERFECT_SCROLLBAR_TABLE_TO_POSITION,
});
