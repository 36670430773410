/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_FORM_ACCOUNT_TAB_OTHER,
  SET_FORM_ACCOUNT_TAB_CAMPAIGNS,
  SET_CURRENT_NAVIGATION_TAB,
  GET_USER_INFO_BY_ID,
  GET_USER_INFO_BY_ID_ERROR,
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_ERROR,
  SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR,
  CANCEL_SUBSCRIPTION_AT_PERIOD_END,
  CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS,
  CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR,
  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
  CREATE_REFERRAL_CODE,
  CREATE_REFERRAL_CODE_ERROR,
  CREATE_REFERRAL_CODE_SUCCESS,
  APPLY_REFERRAL_CODE,
  APPLY_REFERRAL_CODE_ERROR,
  APPLY_REFERRAL_CODE_SUCCESS,
  UPDATE_FILTERS,
  UPDATE_FILTERS_ERROR,
  UPDATE_FILTERS_SUCCESS,
  RETRIEVE_USER_SUBSCRIPTION,
  GET_USER_CAMPAIGNS,
  GET_USER_CAMPAIGNS_SUCCESS,
  GET_USER_CAMPAIGNS_ERROR,
  SET_FILL_DEFAULT_VALUES,
} from 'src/actions/accountSettingsActions';

const formAccountTabOther = {
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const initialAccountSettings = {
  userSubscription: {},
  loadingUserSubscription: false,
};

const initialUserCampaigns = {
  loading: false,
  data: [],
};

const initialCurrentNavigationTab = 'required';

const initialState = {
  accountSettings: initialAccountSettings,
  companyTitles: [],
  campaignSettings: [],
  loading: false,
  updatingFilters: false,
  formAccountTabOther,
  formAccountTabCampaigns: {},
  currentNavigationTab: initialCurrentNavigationTab,
  // fillDefaultValues: false,
  fillDefaultValues: true,
  userCampaigns: initialUserCampaigns,
};

const accountSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_FORM_ACCOUNT_TAB_OTHER: {
      return produce(state, (draft) => {
        draft.formAccountTabOther = payload ? {
          ...state.formAccountTabOther,
          ...payload,
        } : formAccountTabOther;
      });
    }

    case SET_FORM_ACCOUNT_TAB_CAMPAIGNS: {
      return produce(state, (draft) => {
        draft.formAccountTabCampaigns = payload ? {
          ...state.formAccountTabCampaigns,
          ...payload,
        } : {};
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialCurrentNavigationTab;
      });
    }

    case GET_USER_INFO_BY_ID: {
      return produce(state, (draft) => {
        const {
          accountSettings,
          companyTitles,
          campaignSettings,
        } = payload;
        draft.accountSettings = accountSettings;
        draft.companyTitles = companyTitles;
        draft.campaignSettings = campaignSettings;
        draft.loading = false;
      });
    }

    case GET_USER_INFO_BY_ID_ERROR: {
      return produce(state, (draft) => {
        const {
          accountSettings,
          companyTitles,
          campaignSettings,
        } = initialState;
        draft.accountSettings = accountSettings;
        draft.companyTitles = companyTitles;
        draft.campaignSettings = campaignSettings;
        draft.loading = false;
      });
    }

    case GET_USER_CAMPAIGNS: {
      return produce(state, (draft) => {
        draft.userCampaigns.loading = true;
      });
    }

    case GET_USER_CAMPAIGNS_SUCCESS: {
      return produce(state, (draft) => {
        draft.userCampaigns.loading = false;
        draft.userCampaigns.data = payload || [];
      });
    }

    case GET_USER_CAMPAIGNS_ERROR: {
      return produce(state, (draft) => {
        draft.userCampaigns.loading = false;
        draft.userCampaigns.data = [];
      });
    }

    case APPLY_REFERRAL_CODE:
    case CREATE_REFERRAL_CODE:
    case GET_USER_SUBSCRIPTION:
    case SUBSCRIBE:
    case UNSUBSCRIBE:
    case CANCEL_SUBSCRIPTION_AT_PERIOD_END:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD: {
      return produce(state, (draft) => {
        draft.accountSettings.loadingUserSubscription = true;
      });
    }

    case APPLY_REFERRAL_CODE_SUCCESS:
    case CREATE_REFERRAL_CODE_SUCCESS:
    case GET_USER_SUBSCRIPTION_SUCCESS:
    case SUBSCRIBE_SUCCESS:
    case UNSUBSCRIBE_SUCCESS:
    case CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS: {
      return produce(state, (draft) => {
        draft.accountSettings.loadingUserSubscription = false;
        draft.accountSettings.userSubscription = payload ? {
          ...state?.accountSettings?.userSubscription || {},
          ...payload,
        } : initialState.accountSettings.userSubscription;
      });
    }

    case APPLY_REFERRAL_CODE_ERROR:
    case CREATE_REFERRAL_CODE_ERROR:
    case GET_USER_SUBSCRIPTION_ERROR:
    case SUBSCRIBE_ERROR:
    case UNSUBSCRIBE_ERROR:
    case CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR: {
      return produce(state, (draft) => {
        draft.accountSettings.loadingUserSubscription = false;
      });
    }

    case RETRIEVE_USER_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.accountSettings.userSubscription = payload ? {
          ...state?.accountSettings?.userSubscription || {},
          ...payload,
        } : state?.accountSettings?.userSubscription || {};
      });
    }

    case SET_FILL_DEFAULT_VALUES: {
      return produce(state, (draft) => {
        draft.fillDefaultValues = payload;
      });
    }

    case UPDATE_FILTERS: {
      return produce(state, (draft) => {
        draft.accountSettings.updatingFilters = true;
      });
    }

    case UPDATE_FILTERS_ERROR:
    case UPDATE_FILTERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.accountSettings.updatingFilters = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountSettingsReducer;
