/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  setCurrentScrollPerfectScrollbarTablePosition,
  clearMovingScrollPerfectScrollbarTableToPosition,
} from 'src/actions/scrollActions';

import helpers from 'src/helpers';

function PerfectScrollbarTable({
  children,
  subDefaultHeight,
  scrollBoxId,
}) {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const [scrollHeight, setScrollHeight] = useState('none');

  const {
    currentScrollPerfectScrollbarTablePosition,
    moveScrollPerfectScrollbarTableToPosition,
  } = useSelector((state) => state?.scroll || {});

  const {
    shouldSave,
  } = currentScrollPerfectScrollbarTablePosition || {};

  const {
    x: moveToX,
    y: moveToY,
    shouldStart: shouldStartMoveTo,
    perfectScrollbarId = 'scrollBox',
  } = moveScrollPerfectScrollbarTableToPosition || {};

  const getScrollMaxHeightOnWindow = () => {
    const heightW = helpers.transform.toInt10(document.documentElement.clientHeight);
    const heightBeforeScrollBox = helpers.transform.toInt10(document.getElementById('scrollBox')?.offsetTop || '0');
    const heightAfterScrollBox = helpers.transform.toInt10(
      document.getElementById('tablePagination')?.offsetHeight || '0'
    );
    const heightHeaderTopRight = helpers.transform.toInt10(
      document.getElementById('header-top-right')?.offsetHeight || '0'
    );
    const scrollBoxHeight = heightW - heightBeforeScrollBox - heightAfterScrollBox - heightHeaderTopRight - subDefaultHeight;
    return scrollBoxHeight < 300 ? 300 : scrollBoxHeight;
  };

  useEffect(() => {
    const updateScrollHeight = () => {
      setScrollHeight(getScrollMaxHeightOnWindow());
    };
    updateScrollHeight();
    window.addEventListener('resize', updateScrollHeight);
    return () => window.removeEventListener('resize', updateScrollHeight);
  }, []);

  useEffect(() => {
    // call dispatch(saveCurrentScrollDashboardLayoutContentPosition(true));
    // dispatch(saveCurrentScrollPerfectScrollbarTablePosition(true));
    // if you want to save current position
    if (shouldSave && scrollRef) {
      const x = helpers.transform.toInt10(scrollRef.current.scrollLeft);
      const y = helpers.transform.toInt10(scrollRef.current.scrollTop);
      dispatch(setCurrentScrollPerfectScrollbarTablePosition(x, y));
    }
  }, [shouldSave, scrollRef]);

  useEffect(() => {
    if (shouldStartMoveTo && perfectScrollbarId === scrollBoxId && scrollRef) {
      scrollRef.current.scrollLeft = helpers.transform.toInt10(moveToX || 0);
      scrollRef.current.scrollTop = helpers.transform.toInt10(moveToY || 0);
      dispatch(clearMovingScrollPerfectScrollbarTableToPosition());
    }
  }, [shouldStartMoveTo, scrollRef, perfectScrollbarId, scrollBoxId]);

  return (
    <PerfectScrollbar
      id={scrollBoxId || 'scrollBox'}
      style={{ maxHeight: scrollHeight }}
      containerRef={(el) => { scrollRef.current = el; }}
    >
      {children}
    </PerfectScrollbar>
  );
}

PerfectScrollbarTable.propTypes = {
  children: PropTypes.any.isRequired,
  subDefaultHeight: PropTypes.number,
  scrollBoxId: PropTypes.string,
};

PerfectScrollbarTable.defaultProps = {
  subDefaultHeight: 0,
  scrollBoxId: 'scrollBox',
};

export default PerfectScrollbarTable;
