// eslint-disable-next-line import/prefer-default-export
export const CONTACT_TYPE_VALUES = [
  'Party Guest',
  'Customer',
  'Personal Teammate',
  'Downline (Not Personal)',
];

export const CUSTOMER_NEW_EXISTING_TYPE_VALUES = [
  'New Customer',
  'Existing Customer',
];

export const CUSTOMER_ORDERED_VALUES = [
  'Ordered',
  'Has Not Ordered',
];
