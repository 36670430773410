import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_SMART_ACTION = '@smartactions/get-smart-action';
export const GET_SMART_ACTION_SUCCESS = '@smartactions/get-smart-action-success';
export const GET_SMART_ACTION_ERROR = '@smartactions/get-smart-action-error';

export const DELETE_SMART_ACTION = '@smartactions/del-smart-action';
export const DELETE_SMART_ACTION_SUCCESS = '@smartactions/del-smart-action-success';
export const DELETE_SMART_ACTION_ERROR = '@smartactions/del-smart-action-error';
export const CLEAR_SMART_ACTION_WAS_DELETED = '@smartactions/clear-smart-action-was-deleted';

export const UPDATE_SMART_ACTION = '@smartactions/update-smart-action';
export const UPDATE_SMART_ACTION_SUCCESS = '@smartactions/update-smart-action-success';
export const UPDATE_SMART_ACTION_ERROR = '@smartactions/update-smart-action-error';

export const CREATE_SMART_ACTION = '@smartactions/create-smart-action';
export const CREATE_SMART_ACTION_SUCCESS = '@smartactions/create-smart-action-success';
export const CREATE_SMART_ACTION_ERROR = '@smartactions/create-smart-action-error';

export const SET_SMART_ACTION_DETAILS_TAB = '@smartactions/set-smart-action-details-tab';

export const SET_GO_TO_SMART_ACTION_DETAILS = '@smartactions/set-go-to-smart-action-details';
export const CLEAR_GO_TO_SMART_ACTION_DETAILS = '@smartactions/clear-go-to-smart-action-details';

export const SET_FORM_SMART_ACTION = '@smartactions/set-form-smart-action';

export const SET_VALUES_SMART_ACTION_MESSAGE = '@smartactions/set-values-smart-action-message';

export const GET_FOLLOW_UP_MESSAGES = '@smartactions/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@smartactions/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@smartactions/get-follow-up-messages-error';

export const REPLACE_SYSTEM_GEN_MESSAGE = '@smartactions/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS = '@smartactions/replace-system-gen-message-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_ERROR = '@smartactions/replace-system-gen-message-error';

export const SET_PARTY_ID = '@smartactions/set-party-id';

export const getSmartAction = (smartActionId) => async (dispatch) => {
  dispatch({
    type: GET_SMART_ACTION,
  });

  try {
    const response = await axios.get(`/api/scheduledSmartActions/${smartActionId}`);
    dispatch({
      type: GET_SMART_ACTION_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_SMART_ACTION_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_SMART_ACTION_ERROR,
    });
  }
};

export const deleteSmartAction = (smartActionId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SMART_ACTION,
    });
    const response = await axios.delete(`/api/scheduledSmartActions/${smartActionId}`);
    const {
      success,
      error,
    } = response?.data || {};

    dispatch({
      type: DELETE_SMART_ACTION_SUCCESS,
      success,
    });

    if (error) {
      dispatch(addNotification(DELETE_SMART_ACTION, error, NOTIFICATION_STATUS.ERROR));
      return;
    }

    if (!success) {
      dispatch(getSmartAction(smartActionId));
      dispatch(addNotification(DELETE_SMART_ACTION_ERROR, 'Can not delete the action', NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_SMART_ACTION_SUCCESS, 'Smart Action was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
    } = e?.response?.data || {};
    const {
      name: errorName,
    } = error || {};

    dispatch(addNotification(DELETE_SMART_ACTION_ERROR, helpers.transform.toString(errorName || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getSmartAction(smartActionId));
    dispatch({
      type: DELETE_SMART_ACTION_ERROR,
    });
  }
};

export const clearSmartActionWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SMART_ACTION_WAS_DELETED,
  });
};

export const updateSmartAction = (smartActionId, scheduledSmartActionChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SMART_ACTION,
    });
    const response = await axios.put('/api/scheduledSmartActions', {
      scheduledSmartActionChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_SMART_ACTION_ERROR, 'Can not update smart action', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_SMART_ACTION_ERROR,
      });
      return;
    }

    await dispatch(getSmartAction(smartActionId));
    dispatch(addNotification(UPDATE_SMART_ACTION_SUCCESS, 'The smart action has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_SMART_ACTION_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_SMART_ACTION_ERROR, message || errorMessage || 'Smart Action didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_SMART_ACTION_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createSmartAction = (scheduledSmartActionChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SMART_ACTION,
    });
    const response = await axios.post('/api/scheduledSmartActions/create', {
      scheduledSmartActionChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_SMART_ACTION_ERROR, 'Can not create smart action', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_SMART_ACTION_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_SMART_ACTION_SUCCESS, 'Your smart action has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_SMART_ACTION_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_SMART_ACTION_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_SMART_ACTION_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setSmartActionDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_SMART_ACTION_DETAILS_TAB,
    payload: tabName
  });
};

export const setFormSmartAction = (payload) => ({
  type: SET_FORM_SMART_ACTION,
  payload
});

export const setValuesSmartActionMessage = (values) => ({
  type: SET_VALUES_SMART_ACTION_MESSAGE,
  payload: values
});

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const createDynamicFieldObj = async (calledFrom, selectedCustomers, selectedCustomersParties, selectedParties) => {
  const partyId = () => {
    switch (calledFrom) {
      case 'Event Guest List':
        return selectedCustomersParties.every((v, i, a) => i === 0 || v === a[i - 1]) ? selectedCustomersParties[0] : null;
      case 'Event List':
        return selectedParties.length === 1 ? selectedParties[0] : null;
      default:
        return null;
    }
  };

  switch (calledFrom) {
    case 'Contact List':
      return {
        calledBy: selectedCustomers.length === 1 ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
      };

    case 'Event Guest List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        partyId: partyId(),
      };

    case 'Event List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: null,
        partyId: partyId(),
      };

    default:
      return {};
  }
};

export const replaceSystemGenMessage = (systemGenMessage, dynamicFieldsObj) => async (dispatch) => {
  try {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE,
    });
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      ...dynamicFieldsObj,
    }) || {};

    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
      payload: data?.message,
    });
  } catch (e) {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
    });
  }
};

export const setPartyId = () => ({
  type: SET_PARTY_ID,
});
