import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';
// import { getUserInfoById } from 'src/actions/accountSettingsActions';
// import { getAccountInfo } from 'src/actions/accountActions';

export const SET_CUSTOMERS = '@orders/set-customers';
export const GET_CUSTOMERS = '@orders/get-customers';
export const GET_CUSTOMERS_SUCCESS = '@orders/get-customers-success';
export const GET_CUSTOMERS_ERROR = '@orders/get-customers-error';

export const GET_FOLLOW_UP_MESSAGES = '@orders/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@orders/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@orders/get-follow-up-messages-error';

export const GET_PARTIES_LIST = '@customers/get-parties-list';
export const GET_PARTIES_LIST_SUCCESS = '@customers/get-parties-list-success';
export const GET_PARTIES_LIST_ERROR = '@customers/get-parties-list-error';

export const SEND_ADD_PARTY_GUESTS = '@customers/send-add-party-guests';
export const SEND_ADD_PARTY_GUESTS_SUCCESS = '@customers/send-add-party-guests-success';
export const SEND_ADD_PARTY_GUESTS_ERROR = '@customers/send-add-party-guests-error';

export const SET_CUSTOMERS_QUERY = '@orders/set-customers-query';
export const CLEAR_CUSTOMERS_QUERY_FILTER = '@orders/clear-customers-query-filter';

export const GET_PURCHASED_PARTY_OPTIONS = '@orders/get-purchased-party-options';
export const GET_PURCHASED_PARTY_OPTIONS_SUCCESS = '@orders/get-purchased-party-options-success';
export const GET_PURCHASED_PARTY_OPTIONS_ERROR = '@orders/get-purchased-party-options-error';

export const SET_SELECTED_CUSTOMERS = '@orders/set-selected-customers';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@orders/set-visible-modal-form-number';

export const SET_VALUES_FORM_SEND_TEXT = '@orders/set-values-form-send-text';

export const SEND_TEXT_MESSAGE = '@orders/send-text-message';
export const SEND_TEXT_MESSAGE_SUCCESS = '@orders/send-text-message-success';
export const SEND_TEXT_MESSAGE_ERROR = '@orders/send-text-message-error';

export const SEND_ADD_TAG_CAMPAIGN = '@orders/send-add-tag-campaign';
export const SEND_ADD_TAG_CAMPAIGN_SUCCESS = '@orders/send-add-tag-campaign-success';
export const SEND_ADD_TAG_CAMPAIGN_ERROR = '@orders/send-add-tag-campaign-error';

export const SET_VALUES_FORM_ADD_PB_TAG = '@orders/set-values-form-add-pb-tag';

export const SET_VALUES_FORM_ADD_TO_PARTY = '@customers/set-values-form-add-to-party';

export const REPLACE_SYSTEM_GEN_MESSAGE = '@orders/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS = '@orders/replace-system-gen-message-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_ERROR = '@orders/replace-system-gen-message-error';

export const SET_BACK_TO_CUSTOMER_LIST = '@orders/set-back-to-customer-list';

export const SET_BACK_TO_PARTY_GUEST_LIST = '@orders/set-back-to-party-guest-list';

export const GET_PARTY_GUEST = '@orders/get-party-guest';
export const GET_PARTY_GUEST_SUCCESS = '@orders/get-party-guest-success';
export const GET_PARTY_GUEST_ERROR = '@orders/get-party-guest-error';

export const getCustomers = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CUSTOMERS,
  });
  try {
    const response = await axios.post('/api/contacts', getState().customers.query);
    dispatch({
      type: GET_CUSTOMERS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_CUSTOMERS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CUSTOMERS_ERROR,
    });
  }
};

export const setCustomers = (customers) => ({
  type: SET_CUSTOMERS,
  payload: customers
});

export const setCustomersQuery = (query) => ({
  type: SET_CUSTOMERS_QUERY,
  payload: query
});

export const clearCustomersQueryFilter = () => ({
  type: CLEAR_CUSTOMERS_QUERY_FILTER,
});

export const getParties = () => async (dispatch) => {
  dispatch({
    type: GET_PARTIES_LIST,
  });
  try {
    const response = await axios.get('/api/parties/list');
    dispatch({
      type: GET_PARTIES_LIST_SUCCESS,
      payload: response?.data?.parties || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTIES_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTIES_LIST_ERROR,
    });
  }
};

export const sendAddPartyGuests = (addPartyGuests, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_PARTY_GUESTS,
    });
    const response = await axios.put('/api/parties/addpartyguests', { addPartyGuests });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_PARTY_GUESTS_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_PARTY_GUESTS_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_PARTY_GUESTS_SUCCESS, 'The guests have been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_PARTY_GUESTS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_PARTY_GUESTS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_PARTY_GUESTS_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddToParty = (values) => ({
  type: SET_VALUES_FORM_ADD_TO_PARTY,
  payload: values
});

export const getPurchasedPartyOptions = (type) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_PARTY_OPTIONS,
  });
  try {
    const response = await axios.post('/api/contacts/purchase-type-options', { type });
    dispatch({
      type: GET_PURCHASED_PARTY_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_PARTY_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_PARTY_OPTIONS_ERROR,
    });
  }
};

export const setSelectedCustomers = (selectedCustomers) => ({
  type: SET_SELECTED_CUSTOMERS,
  payload: selectedCustomers
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormSendText = (values) => ({
  type: SET_VALUES_FORM_SEND_TEXT,
  payload: values
});

export const sendTextMessage = (sendMessage, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_TEXT_MESSAGE,
    });
    const response = await axios.put('/api/contacts/sendmessage', { sendMessage });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_TEXT_MESSAGE_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_TEXT_MESSAGE_SUCCESS, 'Your text has been scheduled to send.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_TEXT_MESSAGE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_TEXT_MESSAGE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddPBTag = (values) => ({
  type: SET_VALUES_FORM_ADD_PB_TAG,
  payload: values
});

export const sendAddPBTag = (addTagCampaign, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN,
    });
    const response = await axios.put('/api/contacts/addtagcampaign', { addTagCampaign });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_TAG_CAMPAIGN_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_SUCCESS, 'The tag and/or campaign has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const createDynamicFieldObj = async (calledFrom, selectedCustomers, selectedCustomersParties, selectedParties) => {
  // Check to see if all partyId elements are the same.
  const partyId = () => {
    switch (calledFrom) {
      case 'Event Guest List':
        return selectedCustomersParties.every((v, i, a) => i === 0 || v === a[i - 1]) ? selectedCustomersParties[0] : null;
      case 'Event List':
        return selectedParties.length === 1 ? selectedParties[0] : null;
      default:
        return null;
    }
  };

  switch (calledFrom) {
    case 'Contact List':
      return {
        calledBy: selectedCustomers.length === 1 ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        // partyId: selectedCustomersParties.length === 1 ? selectedCustomersParties[0] : null,
      };

    case 'Event Guest List':
      return {
        calledBy: partyId ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        partyId: partyId(),
      };

    case 'Event List':
      return {
        calledBy: partyId ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: null,
        partyId: partyId(),
      };

    default:
      return {};
  }
};

export const replaceSystemGenMessage = (systemGenMessage, dynamicFieldsObj) => async (dispatch) => {
  try {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE,
    });
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      ...dynamicFieldsObj,
    }) || {};

    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
      payload: data?.message,
    });
  } catch (e) {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
    });
  }
};

export const setBackToCustomerList = (payload) => ({
  type: SET_BACK_TO_CUSTOMER_LIST,
  payload,
});

export const clearBackToCustomerList = (clearAll = false) => ({
  type: SET_BACK_TO_CUSTOMER_LIST,
  payload: !clearAll ? {
    customers: [],
    query: {},
  } : false,
});

export const setBackToPartyGuestList = (payload) => ({
  type: SET_BACK_TO_PARTY_GUEST_LIST,
  payload,
});

export const clearBackToPartyGuestList = (clearAll = false) => ({
  type: SET_BACK_TO_PARTY_GUEST_LIST,
  payload: !clearAll ? {
    partyId: '',
  } : false,
});

export const getPartyGuest = (customerId, partyId) => async (dispatch) => {
  dispatch({
    type: GET_PARTY_GUEST,
  });
  try {
    const response = await axios.get(`/api/parties/${partyId}/guest/${customerId}`);
    dispatch({
      type: GET_PARTY_GUEST_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_GUEST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_GUEST_ERROR,
    });
  }
};
