import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const CustomHelpCircle = styled('div')(() => ({
  width: 20,
  marginLeft: 14,

  '&:hover': {
    color: '#5850EC',
  },

  '&.sizeType-15': {
    width: 15,
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
    }
  },

  '&.marginLeft-19': {
    marginLeft: 19,
  },

  '&.marginLeft-5': {
    marginLeft: 5,
  },

  '&.marginLeft-0': {
    marginLeft: 0,
  },

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
    color: 'inherit'
  }
}));

function HelpCircleRoot({
  children,
  ...props
}) {
  return (
    <CustomHelpCircle {...props}>{children}</CustomHelpCircle>
  );
}

HelpCircleRoot.propTypes = {
  children: PropTypes.node,
};

HelpCircleRoot.defaultProps = {
  children: null,
};

export default HelpCircleRoot;
