import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_CURRENT_ORDER = '@orders/set-current-order';
export const SET_ORDER_TO_PROCESSED = '@orders/set-order-to-processed';
export const SET_IS_MODAL_VISIBLE = '@orders/set-modal-visible';
export const SET_MODAL_MESSAGE = '@orders/set-modal-message';
export const SET_IS_MODAL_LOADING = '@orders/set-modal-loading';
export const SET_ORDERS_LOADING = '@orders/set-orders-loading';
export const SET_SELECTED_ORDERS = '@orders/set-selected-orders';
export const GET_ORDERS = '@orders/get-post-orders';
export const GET_ORDERS_SUCCESS = '@orders/get-post-orders-success';
export const GET_ORDERS_ERROR = '@orders/get-post-orders-error';
export const SET_QUERY = '@orders/set-orders-set-query';
export const SET_ORDERS_FILTER = '@orders/set-orders-filter';
export const UPDATE_ORDER = '@orders/update-order';
export const PROCESS_ORDERS = '@orders/process-orders';
export const PROCESS_ORDERS_ERROR = '@orders/process-orders-arror';
export const UPDATE_AND_PROCESS_ORDER = '@orders/update-and-process-orders-arror';
export const CLEAR_ORDERS_AND_QUERY = '@orders/clear-orders-and-query';
export const SET_VALUES_FORM_EDIT_ORDER = '@orders/set-values-form-edit-order';
export const GET_FOLLOW_UP_MESSAGES = '@orders/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@orders/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@orders/get-follow-up-messages-error';
export const REPLACE_SYSTEM_GEN_MESSAGE = '@order-modal/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS = '@orders/replace-system-gen-message-customize';
export const REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_SUCCESS = '@orders/replace-system-gen-message-customize-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_ERROR = '@orders/replace-system-gen-message-customize-error';
export const SET_MODAL_FORM_CUSTOMIZE_ORDERS_VISIBLE = '@orders/set-modal-form-customize-orders-visible';
export const SET_MODAL_FORM_CUSTOMIZE_ORDERS_LOADING = '@orders/set-modal-form-customize-orders-loading';
export const SET_MODAL_FORM_CUSTOMIZE_ORDERS_FORM_DATA = '@orders/set-modal-form-customize-orders-form-data';
export const SAVE_FORM_CUSTOMIZE_ORDERS = '@orders/save-form-customize-orders';

export const DELETE_ORDER = '@orders/delete-order';
export const DELETE_ORDER_SUCCESS = '@orders/delete-order-success';
export const DELETE_ORDER_ERROR = '@orders/delete-order-error';

export const GET_PARTIES_LIST = '@orders/get-parties-list';
export const GET_PARTIES_LIST_SUCCESS = '@orders/get-parties-list-success';
export const GET_PARTIES_LIST_ERROR = '@orders/get-parties-list-error';

export const CLEAR_ORDERS_QUERY_FILTER = '@orders/clear-orders-query-filter';

export const GET_PURCHASED_FROM_HOST_OPTIONS = '@orders/get-purchased-from-host-options';
export const GET_PURCHASED_FROM_HOST_OPTIONS_SUCCESS = '@orders/get-purchased-from-host-options-success';
export const GET_PURCHASED_FROM_HOST_OPTIONS_ERROR = '@orders/get-purchased-from-host-options-error';

export const GET_PURCHASED_FROM_PARTY_OPTIONS = '@orders/get-purchased-from-party-options';
export const GET_PURCHASED_FROM_PARTY_OPTIONS_SUCCESS = '@orders/get-purchased-from-party-options-success';
export const GET_PURCHASED_FROM_PARTY_OPTIONS_ERROR = '@orders/get-purchased-from-party-options-error';

export const GET_PURCHASED_FROM_PARTY_NAME_OPTIONS = '@orders/get-purchased-from-party-name-options';
export const GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_SUCCESS = '@orders/get-purchased-from-party-name-options-success';
export const GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_ERROR = '@orders/get-purchased-from-party-name-options-error';

export const GET_PURCHASED_PRODUCT_OPTIONS = '@orders/get-purchased-product-options';
export const GET_PURCHASED_PRODUCT_OPTIONS_SUCCESS = '@orders/get-purchased-product-options-success';
export const GET_PURCHASED_PRODUCT_OPTIONS_ERROR = '@orders/get-purchased-product-options-error';

export const SET_BACK_TO_ORDER_LIST = '@orders/set-back-to-order-list';

export const setCurrentOrder = (order) => ({
  type: SET_CURRENT_ORDER,
  payload: order
});

export const setOrderToProcessed = (orderId) => ({
  type: SET_ORDER_TO_PROCESSED,
  payload: orderId
});

export const setSelectedOrders = (selectedOrders) => ({
  type: SET_SELECTED_ORDERS,
  payload: selectedOrders
});

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setModalMessage = (isVisible, waituntil) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    isVisible,
    waituntil,
  },
});

export const getOrders = (setSelected = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORDERS,
    });
    const {
      query,
      selectedOrders,
    } = getState().orders || {};
    const { data } = await axios.post('/api/orders', query) || {};

    dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: data
    });
    if (setSelected && data?.orders) {
      const newSelected = selectedOrders.filter((orderID) => data.orders.find((item) => item?.id === orderID));
      if (newSelected && newSelected.length > 0) {
        dispatch(setSelectedOrders(newSelected));
      }
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDERS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDERS_ERROR,
    });
  }
};

export const getParties = () => async (dispatch) => {
  dispatch({
    type: GET_PARTIES_LIST,
  });
  try {
    const response = await axios.get('/api/parties/list');
    dispatch({
      type: GET_PARTIES_LIST_SUCCESS,
      payload: response?.data?.parties || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTIES_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTIES_LIST_ERROR,
    });
  }
};

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const setOrdersLoading = (loading) => ({
  type: SET_ORDERS_LOADING,
  payload: loading
});

export const processUpdateOrder = (ordersId) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    const response = await axios.put('/api/orders/process', {
      ordersToProcess: {
        ids: helpers.transform.toArray(ordersId)
      }
    });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(PROCESS_ORDERS_ERROR, error || 'Could not process the order', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: PROCESS_ORDERS_ERROR,
      });
      return;
    }

    dispatch(addNotification(PROCESS_ORDERS, 'Processing Order...', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_AND_PROCESS_ORDER,
    });
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(PROCESS_ORDERS_ERROR, error || 'Could not process the order', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: PROCESS_ORDERS_ERROR,
    });
  }
};

export const updateOrder = (orderChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch(setIsModalLoading(true));
    const { processedOrder } = getState().orders;
    const response = await axios.put('/api/orders', { orderChanges });
    const { error } = response?.data || {};

    if (error) {
      dispatch(addNotification(UPDATE_ORDER, error, NOTIFICATION_STATUS.ERROR));
      dispatch(setIsModalLoading(false));
      return;
    }

    dispatch(addNotification(UPDATE_ORDER, 'Order updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_ORDER,
      payload: response?.data?.order,
    });

    if (processedOrder) {
      await dispatch(processUpdateOrder(processedOrder));
    }

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      status,
      data
    } = e?.response || {};
    const {
      error,
      waituntil,
      message,
    } = data || {};

    if (status === 429) {
      dispatch(setModalMessage(true, waituntil));
    }
    dispatch(addNotification(UPDATE_ORDER, error || message || 'Order didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch(setIsModalLoading(false));

    if (doWhenCatch) {
      doWhenCatch();
    }
  }
};

export const setOrderQuery = (query) => ({
  type: SET_QUERY,
  payload: query
});

export const setOrderFilter = (filters) => ({
  type: SET_ORDERS_FILTER,
  payload: filters
});

export const clearOrdersAndQuery = () => ({
  type: CLEAR_ORDERS_AND_QUERY,
});

export const clearOrdersQueryFilter = () => ({
  type: CLEAR_ORDERS_QUERY_FILTER,
});

export const setValuesFormEditOrder = (values) => ({
  type: SET_VALUES_FORM_EDIT_ORDER,
  payload: values
});

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const replaceSystemGenMessage = (calledFrom, systemGenMessage, dynamicFieldsObj) => async (dispatch) => {
  try {
    if (calledFrom === 'Edit Order') {
      dispatch(setIsModalLoading(true));
    } else {
      dispatch({
        type: REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS,
      });
    }
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      ...dynamicFieldsObj,
    }) || {};

    if (calledFrom === 'Edit Order') {
      dispatch({
        type: REPLACE_SYSTEM_GEN_MESSAGE,
        payload: data?.message,
      });
    } else {
      dispatch({
        type: REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_SUCCESS,
        payload: data?.message,
      });
    }
  } catch (e) {
    if (calledFrom === 'Edit Order') {
      dispatch(setIsModalLoading(false));
    } else {
      dispatch({
        type: REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_ERROR,
      });
    }
  }
};

export const setModalFormCustomizeOrdersVisible = (isModalVisible = false) => ({
  type: SET_MODAL_FORM_CUSTOMIZE_ORDERS_VISIBLE,
  payload: isModalVisible
});

export const setModalFormCustomizeOrdersLoading = (loading) => ({
  type: SET_MODAL_FORM_CUSTOMIZE_ORDERS_LOADING,
  payload: loading
});

export const setModalFormCustomizeOrdersFormData = (formData) => ({
  type: SET_MODAL_FORM_CUSTOMIZE_ORDERS_FORM_DATA,
  payload: formData
});

export const saveFormCustomizeOrders = (orderChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setModalFormCustomizeOrdersLoading(true));
    const response = await axios.put('/api/orders/updatecustommessage', { orderChanges }) || {};
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SAVE_FORM_CUSTOMIZE_ORDERS, 'Try again later', NOTIFICATION_STATUS.ERROR));
      doWhenCatch();
      dispatch(setModalFormCustomizeOrdersLoading(false));
      return;
    }
    doWhenTry();
    dispatch(addNotification(SAVE_FORM_CUSTOMIZE_ORDERS, 'The orders have been successfully updated.', NOTIFICATION_STATUS.SUCCESS));
    dispatch(setModalFormCustomizeOrdersLoading(false));
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SAVE_FORM_CUSTOMIZE_ORDERS, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    doWhenCatch();
    dispatch(setModalFormCustomizeOrdersLoading(false));
  }
};

export const getPurchasedFromHostOptions = (status) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_FROM_HOST_OPTIONS,
  });
  try {
    const response = await axios.post('/api/orders/purchase-from-host-options', { status });
    dispatch({
      type: GET_PURCHASED_FROM_HOST_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_FROM_HOST_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_FROM_HOST_OPTIONS_ERROR,
    });
  }
};

export const getPurchasedFromPartyOptions = (status) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_FROM_PARTY_OPTIONS,
  });
  try {
    const response = await axios.post('/api/orders/purchase-from-party-options', { status });
    dispatch({
      type: GET_PURCHASED_FROM_PARTY_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_FROM_PARTY_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_FROM_PARTY_OPTIONS_ERROR,
    });
  }
};

export const getPurchasedFromPartyNameOptions = (status) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_FROM_PARTY_NAME_OPTIONS,
  });
  try {
    const response = await axios.post('/api/orders/purchase-from-party-name-options', { status });
    dispatch({
      type: GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_ERROR,
    });
  }
};

export const getPurchasedProductOptions = (status) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_PRODUCT_OPTIONS,
  });
  try {
    const response = await axios.post('/api/orders/purchase-product-options', { status });
    dispatch({
      type: GET_PURCHASED_PRODUCT_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_PRODUCT_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_PRODUCT_OPTIONS_ERROR,
    });
  }
};

export const deleteOrder = (orderId, doWhenTry = () => {}, doWhenCatch = () => {}) => async (dispatch) => {
  dispatch({
    type: DELETE_ORDER,
  });
  try {
    const response = await axios.delete(`/api/orders/${orderId}`);
    const payload = response?.data?.success;
    if (payload) {
      dispatch({
        type: DELETE_ORDER_SUCCESS,
      });
      dispatch(addNotification(DELETE_ORDER_SUCCESS, 'Deleted the Order', NOTIFICATION_STATUS.SUCCESS));
    } else {
      dispatch({
        type: DELETE_ORDER_ERROR,
      });
      dispatch(addNotification(DELETE_ORDER_ERROR, `Could not delete the order ${orderId}. Please reload this page`, NOTIFICATION_STATUS.ERROR));
    }
    dispatch(setIsModalLoading(false));
    doWhenTry();
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_ORDER_ERROR, helpers.transform.toString(message || error || 'Could not delete the order'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: DELETE_ORDER_ERROR,
    });
    dispatch(setIsModalLoading(false));
    doWhenCatch();
  }
};

export const setBackToOrderList = (payload) => ({
  type: SET_BACK_TO_ORDER_LIST,
  payload,
});

export const clearBackToOrderList = (clearAll = false) => ({
  type: SET_BACK_TO_ORDER_LIST,
  payload: !clearAll ? {
    orders: [],
    query: {},
  } : false,
});
