/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_CURRENT_NAVIGATION_TAB,
  GET_PARTY_SETTINGS_BY_ID,
  GET_PARTY_SETTINGS_BY_ID_ERROR,
  SET_FILL_DEFAULT_VALUES,
  SET_FORM_PARTY_SETTINGS_TAB_GENERAL,
  GET_TABLE_PARTY_TYPES,
  GET_TABLE_PARTY_TYPES_SUCCESS,
  GET_TABLE_PARTY_TYPES_ERROR,
  GET_NON_TABLE_PARTY_TYPES,
  GET_NON_TABLE_PARTY_TYPES_SUCCESS,
  GET_NON_TABLE_PARTY_TYPES_ERROR,
} from 'src/actions/partySettingsActions';

const initialPartySettings = {
};

const initialCurrentNavigationTab = 'partyTypes';

const tablePartyTypes = {
  tablePartyTypeList: [],
  loading: false,
};

const nonTablePartyTypes = {
  nonTablePartyTypeList: [],
  loading: false,
};

const initialState = {
  partySettings: initialPartySettings,
  loading: false,
  currentNavigationTab: initialCurrentNavigationTab,
  fillDefaultValues: false,
  formPartySettingsTabGeneral: {},
  tablePartyTypes,
  nonTablePartyTypes,
};

const partySettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialCurrentNavigationTab;
      });
    }

    case GET_PARTY_SETTINGS_BY_ID: {
      return produce(state, (draft) => {
        const { partySettings } = payload;
        draft.partySettings = partySettings;
        draft.loading = false;
      });
    }

    case GET_PARTY_SETTINGS_BY_ID_ERROR: {
      return produce(state, (draft) => {
        const { partySettings } = initialState;
        draft.partySettings = partySettings;
        draft.loading = false;
      });
    }

    case SET_FILL_DEFAULT_VALUES: {
      return produce(state, (draft) => {
        draft.fillDefaultValues = payload;
      });
    }

    case SET_FORM_PARTY_SETTINGS_TAB_GENERAL: {
      return produce(state, (draft) => {
        draft.formPartySettingsTabGeneral = payload ? {
          ...state.formPartySettingsTabGeneral,
          ...payload,
        } : initialState.formPartySettingsTabGeneral;
      });
    }

    case GET_TABLE_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.tablePartyTypes.loading = true;
      });
    }

    case GET_TABLE_PARTY_TYPES_SUCCESS:
    case GET_TABLE_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.tablePartyTypes.loading = false;
        draft.tablePartyTypes.tablePartyTypeList = payload ? [
          ...state.tablePartyTypes.tablePartyTypeList,
          ...payload,
        ] : tablePartyTypes.tablePartyTypeList;
      });
    }

    case GET_NON_TABLE_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.nonTablePartyTypes.loading = true;
      });
    }

    case GET_NON_TABLE_PARTY_TYPES_SUCCESS:
    case GET_NON_TABLE_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.nonTablePartyTypes.loading = false;
        draft.nonTablePartyTypes.nonTablePartyTypeList = payload ? [
          ...state.nonTablePartyTypes.nonTablePartyTypeList,
          ...payload,
        ] : nonTablePartyTypes.nonTablePartyTypeList;
      });
    }

    default: {
      return state;
    }
  }
};

export default partySettingsReducer;
