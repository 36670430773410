import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomFormControlLabel = styled(FormControlLabel)((props) => ({
  marginTop: '7px',
  [props.theme.breakpoints.down('lg')]: {
    marginTop: '15px',
  },
  '& .MuiTypography-body1': {
    marginLeft: '1px',
  },
}));

function FormControlLabelCheckboxV2({
  children,
  ...props
}) {
  return (
    <CustomFormControlLabel {...props}>{children}</CustomFormControlLabel>
  );
}

FormControlLabelCheckboxV2.propTypes = {
  children: PropTypes.node,
};

FormControlLabelCheckboxV2.defaultProps = {
  children: null,
};

export default FormControlLabelCheckboxV2;
