import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_LOADING = '@orders/set-loading';
export const SET_CURRENT_NAVIGATION_TAB = '@partySettings/set-current-navigation-tab';

export const GET_PARTY = '@orders/get-party';
export const GET_PARTY_SUCCESS = '@orders/get-party-success';
export const GET_PARTY_ERROR = '@orders/get-party-error';

export const DELETE_PARTY = '@orders/del-party';
export const DELETE_PARTY_SUCCESS = '@orders/del-party-success';
export const DELETE_PARTY_ERROR = '@orders/del-party-error';
export const CLEAR_PARTY_WAS_DELETED = '@orders/clear-party-was-deleted';

export const UPDATE_PARTY = '@orders/update-party';
export const UPDATE_PARTY_SUCCESS = '@orders/update-party-success';
export const UPDATE_PARTY_ERROR = '@orders/update-party-error';

export const CREATE_PARTY = '@orders/create-party';
export const CREATE_PARTY_SUCCESS = '@orders/create-party-success';
export const CREATE_PARTY_ERROR = '@orders/create-party-error';

export const UPDATE_PARTY_GUEST_MESSAGING = '@party/update-party-type-guest-messaging';
export const UPDATE_PARTY_GUEST_MESSAGING_SUCCESS = '@party/update-party-type-guest-messaging-success';
export const UPDATE_PARTY_GUEST_MESSAGING_ERROR = '@party/update-party-type-guest-messaging-error';

export const GET_PARTY_SOURCES = '@party/get-party-sources';
export const GET_PARTY_SOURCES_SUCCESS = '@party/get-party-sources-success';
export const GET_PARTY_SOURCES_ERROR = '@party/get-party-sources-error';

export const SET_PARTY_DETAILS_TAB = '@orders/set-party-details-tab';

export const SET_GO_TO_PARTY_DETAILS = '@orders/set-go-to-party-details';
export const CLEAR_GO_TO_PARTY_DETAILS = '@orders/clear-go-to-party-details';

export const SET_FORM_CREATE_UPDATE_PARTY = '@orders/set-form-create-update-party';

export const SET_FORM_UPDATE_PARTY_GUEST_MESSAGING = '@party/set-form-update-party-guest-messaging';

export const SET_VALUES_WELCOME_MESSAGE = '@party/set-values-welcome-message';

export const SET_VALUES_FORM_ADD_PARTY_GUEST = '@orders/set-values-form-add-party-guest';

export const SEND_ADD_PARTY_GUEST = '@orders/send-add-party-guest';
export const SEND_ADD_PARTY_GUEST_SUCCESS = '@orders/send-add-party-guest-success';
export const SEND_ADD_PARTY_GUEST_ERROR = '@orders/send-add-party-guest-error';

export const SET_VALUES_FORM_ADD_PARTY_ORDER = '@orders/set-values-form-add-party-order';

export const SEND_REMOVE_PARTY_GUESTS = '@orders/send-remove-party-guest';
export const SEND_REMOVE_PARTY_GUESTS_SUCCESS = '@orders/send-remove-party-guest-success';
export const SEND_REMOVE_PARTY_GUESTS_ERROR = '@orders/send-remove-party-guest-error';

export const SEND_ADD_PARTY_ORDER = '@orders/send-add-party-order';
export const SEND_ADD_PARTY_ORDER_SUCCESS = '@orders/send-add-party-order-success';
export const SEND_ADD_PARTY_ORDER_ERROR = '@orders/send-add-party-order-error';

export const SEND_REMOVE_PARTY_ORDERS = '@orders/send-remove-party-order';
export const SEND_REMOVE_PARTY_ORDERS_SUCCESS = '@orders/send-remove-party-order-success';
export const SEND_REMOVE_PARTY_ORDERS_ERROR = '@orders/send-remove-party-order-error';

export const GET_CONTACTS_LIST = '@account/get-contacts-list';
export const GET_CONTACTS_LIST_SUCCESS = '@account/get-contacts-list-success';
export const GET_CONTACTS_LIST_ERROR = '@account/get-contacts-list-error';

export const GET_ORDERS_LIST = '@account/get-orders-list';
export const GET_ORDERS_LIST_SUCCESS = '@account/get-orderss-list-success';
export const GET_ORDERS_LIST_ERROR = '@account/get-orders-list-error';

export const SET_PARTY_GUESTS = '@orders/set-party-guests';
export const GET_PARTY_GUESTS = '@orders/get-party-guests';
export const GET_PARTY_GUESTS_SUCCESS = '@orders/get-party-guests-success';
export const GET_PARTY_GUESTS_ERROR = '@orders/get-party-guests-error';

export const SET_PARTY_GUESTS_QUERY = '@orders/set-party-guests-query';

export const SET_PARTY_ORDERS = '@orders/set-party-orders';
export const GET_PARTY_ORDERS = '@orders/get-party-orders';
export const GET_PARTY_ORDERS_SUCCESS = '@orders/get-party-orders-success';
export const GET_PARTY_ORDERS_ERROR = '@orders/get-party-orders-error';

export const SET_PARTY_ORDERS_QUERY = '@orders/set-party-orders-query';

export const GET_FOLLOW_UP_MESSAGES = '@orders/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@orders/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@orders/get-follow-up-messages-error';

export const REPLACE_SYSTEM_GEN_MESSAGE = '@orders/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS = '@orders/replace-system-gen-message-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_ERROR = '@orders/replace-system-gen-message-error';

export const SEND_TEXT_MESSAGE = '@orders/send-text-message';
export const SEND_TEXT_MESSAGE_SUCCESS = '@orders/send-text-message-success';
export const SEND_TEXT_MESSAGE_ERROR = '@orders/send-text-message-error';

export const SET_SELECTED_PARTY_GUESTS = '@orders/set-selected-party-guests';
export const SET_SELECTED_CONTACTS = '@orders/set-selected-contacts';
export const SET_SELECTED_PARTY_ORDERS = '@orders/set-selected-party-orders';
export const SET_SELECTED_ORDERS = '@orders/set-selected-orders';

export const SET_VALUES_FORM_SEND_TEXT = '@orders/set-values-form-send-text';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@orders/set-visible-modal-form-number';

export const GET_PARTY_TYPES = '@partyDetails/get-party-types';
export const GET_PARTY_TYPES_SUCCESS = '@partyDetails/get-party-types-success';
export const GET_PARTY_TYPES_ERROR = '@partyDetails/get-party-types-error';

export const SET_VALUES_FORM_ADD_PB_TAG = '@partyDetails/set-values-form-add-pb-tag';

export const SEND_ADD_TAG_CAMPAIGN = '@partyDetails/send-add-tag-campaign';
export const SEND_ADD_TAG_CAMPAIGN_SUCCESS = '@partyDetails/send-add-tag-campaign-success';
export const SEND_ADD_TAG_CAMPAIGN_ERROR = '@partyDetails/send-add-tag-campaign-error';

export const SET_UPDATE_UPDATE_SMART_ACTION_DATES = '@partyDetails/set-update-update-smart-action-dates';

export const SET_UPDATE_UPDATE_PARTY_TYPE_COMPONENTS = '@partyDetails/set-update-update-party-type-components';

export const SET_FORM_MODIFY_GUESTS = '@partyDetails/set-form-modify-guests';

export const MODIFY_GUESTS = '@party/modify-guests';
export const MODIFY_GUESTS_SUCCESS = '@party/modify-guests-success';
export const MODIFY_GUESTS_ERROR = '@party/modify-guests-error';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});

export const setFormModifyGuests = (payload) => ({
  type: SET_FORM_MODIFY_GUESTS,
  payload
});

export const getParty = (partyId) => async (dispatch) => {
  dispatch({
    type: GET_PARTY,
  });
  try {
    const response = await axios.get(`/api/parties/${partyId}`);
    dispatch({
      type: GET_PARTY_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_ERROR,
    });
  }
};

export const deleteParty = (partyId) => async (dispatch) => {
  dispatch({
    type: DELETE_PARTY,
  });
  try {
    const response = await axios.delete(`/api/parties/${partyId}`);
    const payload = response?.data?.success;
    dispatch({
      type: DELETE_PARTY_SUCCESS,
      payload,
    });
    if (!payload) {
      dispatch(getParty(partyId));
      dispatch(addNotification(DELETE_PARTY_ERROR, `Can not delete event ${partyId}`, NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_PARTY_SUCCESS, 'Event was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_PARTY_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getParty(partyId));
    dispatch({
      type: DELETE_PARTY_ERROR,
    });
  }
};

export const clearPartyWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PARTY_WAS_DELETED,
  });
};

export const updateParty = (partyId, partyChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PARTY,
    });
    const {
      updateSmartActionDates,
      updatePartyTypeComponents,
      party,
    } = getState()?.partyDetails || {};
    const {
      partyStartDateTime: originalPartyStartDateTime,
      hostPhone: originalHostPhone,
    } = party || {};
    const response = await axios.put('/api/parties', {
      partyChanges,
      updateSmartActionDates,
      updatePartyTypeComponents,
      originalPartyStartDateTime,
      originalHostPhone,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_PARTY_ERROR, 'Can not update event', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_PARTY_ERROR,
      });
      return;
    }

    await dispatch(getParty(partyId));
    dispatch(addNotification(UPDATE_PARTY_SUCCESS, 'The event has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_PARTY_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_PARTY_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_PARTY_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createParty = (partyChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PARTY,
    });
    const response = await axios.post('/api/parties/create', {
      partyChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_PARTY_ERROR, 'Can not create event', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_PARTY_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_PARTY_SUCCESS, 'Your event has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_PARTY_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_PARTY_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_PARTY_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updatePartyGuestMessaging = (partyId, partyGuestMessagingChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PARTY_GUEST_MESSAGING,
    });
    const response = await axios.put('/api/parties/partyGuestMessaging', {
      partyGuestMessagingChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_PARTY_GUEST_MESSAGING_ERROR, 'Can not update event', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_PARTY_GUEST_MESSAGING_ERROR,
      });
      return;
    }

    await dispatch(getParty(partyId));
    dispatch(addNotification(UPDATE_PARTY_GUEST_MESSAGING_SUCCESS, 'Join Actions has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_PARTY_GUEST_MESSAGING_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_PARTY_GUEST_MESSAGING_ERROR, errorMessage || 'Could not update the message, tags, or campaigns.', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_PARTY_GUEST_MESSAGING_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setPartyDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_PARTY_DETAILS_TAB,
    payload: tabName
  });
};

export const setGoToOrderDetails = (partyId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_PARTY_DETAILS,
    payload: {
      partyId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToOrderDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_PARTY_DETAILS,
    payload: all,
  });
};

export const setFormCreateUpdateParty = (payload) => ({
  type: SET_FORM_CREATE_UPDATE_PARTY,
  payload
});

export const getPartyGuests = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_GUESTS,
  });

  const {
    sort,
    search = '',
    filters = {},
  } = getState()?.partyDetails?.query || {};

  const {
    ordered,
    customerNew,
    joinedEventDateFrom,
    joinedEventDateTo,
    joiningList,
    hostingList,
    sourceList,
  } = filters || {};

  const params = {
    sort,
    search,
    filters: {
      ordered,
      customerNew,
      joinedEventDateFrom,
      joinedEventDateTo,
      joiningList,
      hostingList,
      sourceList
    },
    screen: 'party',
  };

  try {
    const response = await axios.post(`/api/parties/${getState().partyDetails.party.id}/guests`, { params });
    dispatch({
      type: GET_PARTY_GUESTS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_GUESTS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_GUESTS_ERROR,
    });
  }
};

export const setPartyGuests = (partyGuests) => ({
  type: SET_PARTY_GUESTS,
  payload: partyGuests,
});

export const setPartyGuestsQuery = (query) => ({
  type: SET_PARTY_GUESTS_QUERY,
  payload: query
});

export const setSelectedPartyGuests = (selectedPartyGuests) => ({
  type: SET_SELECTED_PARTY_GUESTS,
  payload: selectedPartyGuests
});

export const getPartySourcesList = () => async (dispatch) => {
  dispatch({
    type: GET_PARTY_SOURCES,
  });

  try {
    const response = await axios.get('/api/parties/party-sources-list');
    dispatch({
      type: GET_PARTY_SOURCES_SUCCESS,
      payload: response?.data || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_SOURCES_ERROR, helpers.transform.toString(message || error?.message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_SOURCES_ERROR,
    });
  }
};

export const getPartyOrders = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_ORDERS,
  });

  const params = {
    sort: getState().partyDetails.ordersQuery.sort,
    screen: 'party',
  };

  try {
    const response = await axios.post(`/api/parties/${getState().partyDetails.party.id}/orders`, { params });
    dispatch({
      type: GET_PARTY_ORDERS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_ORDERS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_ORDERS_ERROR,
    });
  }
};

export const setPartyOrders = (PartyOrders) => ({
  type: SET_PARTY_ORDERS,
  payload: PartyOrders,
});

export const setPartyOrdersQuery = (query) => ({
  type: SET_PARTY_ORDERS_QUERY,
  payload: query
});

export const setSelectedPartyOrders = (selectedPartyOrders) => ({
  type: SET_SELECTED_PARTY_ORDERS,
  payload: selectedPartyOrders
});

export const setSelectedContacts = (selectedContacts) => ({
  type: SET_SELECTED_CONTACTS,
  payload: selectedContacts
});

export const setSelectedOrders = (selectedOrders) => ({
  type: SET_SELECTED_ORDERS,
  payload: selectedOrders
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormSendText = (values) => ({
  type: SET_VALUES_FORM_SEND_TEXT,
  payload: values
});

export const sendTextMessage = (sendMessage, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_TEXT_MESSAGE,
    });
    const response = await axios.put('/api/contacts/sendmessage', { sendMessage });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_TEXT_MESSAGE_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_TEXT_MESSAGE_SUCCESS, 'Your text has been scheduled to send.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_TEXT_MESSAGE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_TEXT_MESSAGE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddPBTag = (values) => ({
  type: SET_VALUES_FORM_ADD_PB_TAG,
  payload: values
});

export const sendAddPBTag = (addTagCampaign, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN,
    });
    const response = await axios.put('/api/contacts/addtagcampaign', { addTagCampaign });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_TAG_CAMPAIGN_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_SUCCESS, 'The tag and/or campaign has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddPartyGuest = (values) => ({
  type: SET_VALUES_FORM_ADD_PARTY_GUEST,
  payload: values
});

export const sendAddPartyGuest = (addPartyGuests, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_PARTY_GUEST,
    });
    const response = await axios.put('/api/parties/addpartyguests', { addPartyGuests });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_PARTY_GUEST_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_PARTY_GUEST_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_PARTY_GUEST_SUCCESS, 'The guest has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_PARTY_GUEST_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_PARTY_GUEST_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_PARTY_GUEST_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const sendRemovePartyGuests = (selectedPartyGuests, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_REMOVE_PARTY_GUESTS,
    });
    const response = await axios.put('/api/parties/removepartyguests', { selectedPartyGuests });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_REMOVE_PARTY_GUESTS_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_REMOVE_PARTY_GUESTS_ERROR,
      });
      return;
    }

    const removeSuccessMessage = selectedPartyGuests.length > 1 ? 'The guests have been removed' : 'The guest has been removed';

    dispatch(addNotification(SEND_REMOVE_PARTY_GUESTS_SUCCESS, removeSuccessMessage, NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_REMOVE_PARTY_GUESTS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_REMOVE_PARTY_GUESTS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_REMOVE_PARTY_GUESTS_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddPartyOrder = (values) => ({
  type: SET_VALUES_FORM_ADD_PARTY_ORDER,
  payload: values
});

export const sendAddPartyOrder = (addPartyOrders, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_PARTY_ORDER,
    });
    const response = await axios.put('/api/parties/addpartyorders', { addPartyOrders });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_PARTY_ORDER_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_PARTY_ORDER_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_PARTY_ORDER_SUCCESS, 'The order has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_PARTY_ORDER_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_PARTY_ORDER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_PARTY_ORDER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const sendRemovePartyOrders = (selectedPartyOrders, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_REMOVE_PARTY_ORDERS,
    });
    const response = await axios.put('/api/parties/removepartyorders', { selectedPartyOrders });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_REMOVE_PARTY_ORDERS_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_REMOVE_PARTY_ORDERS_ERROR,
      });
      return;
    }

    const removeSuccessMessage = selectedPartyOrders.length > 1 ? 'The orders have been removed' : 'The order has been removed';

    dispatch(addNotification(SEND_REMOVE_PARTY_ORDERS_SUCCESS, removeSuccessMessage, NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_REMOVE_PARTY_ORDERS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_REMOVE_PARTY_ORDERS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_REMOVE_PARTY_ORDERS_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getUserContacts = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTACTS_LIST,
    });
    const response = await axios.get('/api/contacts/contactlist');
    const { error, message } = response?.data || {};
    if (error || message) {
      dispatch(addNotification(GET_CONTACTS_LIST_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_CONTACTS_LIST_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_CONTACTS_LIST_SUCCESS,
      payload: response?.data?.contacts || [],
    });
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(GET_CONTACTS_LIST_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CONTACTS_LIST_ERROR,
    });
  }
};

export const getUserOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDERS_LIST,
    });
    const response = await axios.get('/api/orders/orderslist');
    const { error, message } = response?.data || {};
    if (error || message) {
      dispatch(addNotification(GET_ORDERS_LIST_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_ORDERS_LIST_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_ORDERS_LIST_SUCCESS,
      payload: response?.data?.orders || [],
    });
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDERS_LIST_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDERS_LIST_ERROR,
    });
  }
};

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const replaceSystemGenMessage = (systemGenMessage, dynamicFieldsObj) => async (dispatch) => {
  try {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE,
    });
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      ...dynamicFieldsObj,
    }) || {};

    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
      payload: data?.message,
    });
  } catch (e) {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
    });
  }
};

export const setFormPartyGuestMessaging = (payload) => ({
  type: SET_FORM_UPDATE_PARTY_GUEST_MESSAGING,
  payload
});

export const setValuesWelcomeMessage = (values) => ({
  type: SET_VALUES_WELCOME_MESSAGE,
  payload: values
});

export const createDynamicFieldObj = async (calledFrom, selectedCustomers, selectedCustomersParties, selectedParties) => {
  const partyId = () => {
    switch (calledFrom) {
      case 'Event Guest List':
        return selectedCustomersParties.every((v, i, a) => i === 0 || v === a[i - 1]) ? selectedCustomersParties[0] : null;
      case 'Event List':
        return selectedParties.length === 1 ? selectedParties[0] : null;
      default:
        return null;
    }
  };

  switch (calledFrom) {
    case 'Contact List':
      return {
        calledBy: selectedCustomers.length === 1 ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
      };

    case 'Event Guest List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        partyId: partyId(),
      };

    case 'Event List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: null,
        partyId: partyId(),
      };

    default:
      return {};
  }
};

export const getPartyTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PARTY_TYPES,
    });
    const { data } = await axios.post('/api/partySettings/partyTypes', { status: 'Active' });
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_PARTY_TYPES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_PARTY_TYPES_SUCCESS,
      payload: data?.partyTypes || {},
    });
  } catch (e) {
    dispatch({
      type: GET_PARTY_TYPES_ERROR,
    });
  }
};

export const clearPartyTypes = () => ({
  type: GET_PARTY_TYPES_SUCCESS,
});

export const setUpdateUpdateSmartActionDates = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_UPDATE_SMART_ACTION_DATES,
    payload,
  });
};

export const setUpdateUpdatePartyTypeComponents = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_UPDATE_PARTY_TYPE_COMPONENTS,
    payload,
  });
};

export const saveFormModifyGuests = (guestChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setFormModifyGuests({ loading: true }));
    const response = await axios.put('/api/parties/updatepartyguests', { guestChanges }) || {};
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(MODIFY_GUESTS_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      doWhenCatch();
      dispatch(setFormModifyGuests({ loading: false }));
      return;
    }
    doWhenTry();
    dispatch(addNotification(MODIFY_GUESTS_SUCCESS, 'The guests have been successfully updated.', NOTIFICATION_STATUS.SUCCESS));
    dispatch(setFormModifyGuests({ loading: false }));
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(MODIFY_GUESTS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    doWhenCatch();
    dispatch(setFormModifyGuests({ loading: false }));
  }
};
