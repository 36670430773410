import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Radio,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
} from '@mui/material';
import Calendar from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import { withStyles } from 'tss-react/mui';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import NumberFormat from 'react-number-format';
import TextFieldFormikContainer from 'src/components/TextFieldFormik/TextFieldFormikContainer';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabelCheckboxV2 from 'src/components/TextFieldFormik/styled/FormControlLabelCheckboxV2';

import textFieldFormikStyle from 'src/components/TextFieldFormik/textFieldFormik.style';
import helpers from 'src/helpers';
import moment from 'moment';

function TextFieldFormik({
  children,
  classes,
  showInfo,
  isShowInfoV2,
  touchedValue,
  errorValue,
  handleChange,
  handleBlur,
  disabledBox,
  fieldType,
  value,
  formControlLabel,
  sizeInput,
  addHighlights,
  iconOnError,
  helpInfoMarginLeft,
  maxWidthSubtract,
  redirectURL,
  tooltipText,
  handleClickRedirect,
  minDate,
  maxDate,
  ...props
}) {
  const getFieldType = () => {
    switch (fieldType) {
      case 'phoneNumber':
        return (
          <NumberFormat
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            customInput={TextField}
            value={value}
            {...(!helpers.isPhoneLengthStandard(value) && !helpers.isPhoneLengthStandard(value)
              ? {}
              : { format: '(###) ###-####' }
            )}
            {...props}
          />
        );
      case 'uscurrency':
        return (
          <NumberFormat
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            customInput={TextField}
            value={value}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...props}
          />
        );
      case 'numberFormat':
        return (
          <NumberFormat
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            customInput={TextField}
            value={value}
            {...props}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            className={classes.checkbox}
            control={(
              <Checkbox
                checked={value}
                onChange={handleChange}
                value={value}
                color="secondary"
                {...props}
              />
            )}
            label={formControlLabel}
          />
        );
      case 'checkboxV2':
        return (
          <FormControlLabelCheckboxV2
            control={(
              <Checkbox
                checked={value}
                onChange={handleChange}
                value={value}
                color="secondary"
                {...props}
              />
            )}
            label={formControlLabel}
          />
        );
      case 'radio':
        return (
          <FormControlLabel
            className={classes.radio}
            control={(
              <Radio
                value={value}
                color="secondary"
                {...props}
              />
            )}
            label={formControlLabel}
          />
        );
      case 'date':
        return (
          <MobileDatePicker
            onBlur={handleBlur}
            onChange={handleChange}
            value={value ? moment(value) : null}
            format="MM/DD/YYYY"
            slotProps={{
              textField: {
                fullWidth: true,
                size: props?.size ?? 'medium',
                error: Boolean(touchedValue && errorValue),
                helperText: touchedValue && errorValue,
                InputProps: {
                  endAdornment: value ? (
                    <IconButton
                      aria-label="clear"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="calendar">
                      <Calendar />
                    </IconButton>
                  )
                },
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept']
              }
            }}
            minDate={minDate ? moment(minDate) : null}
            maxDate={maxDate ? moment(maxDate) : null}
            {...props}
          />
        );
      case 'dateTime':
        return (
          <MobileDateTimePicker
            onBlur={handleBlur}
            onChange={handleChange}
            closeOnSelect
            value={value ? moment(value) : null}
            format="MM/DD/YYYY hh:mm A"
            ampm
            slotProps={{
              textField: {
                fullWidth: true,
                size: props?.size ?? 'medium',
                InputProps: {
                  endAdornment: value ? (
                    <IconButton
                      aria-label="clear"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="calendar">
                      <Calendar />
                    </IconButton>
                  )
                },
                error: Boolean(touchedValue && errorValue),
                helperText: touchedValue && errorValue
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept']
              }
            }}
            minDate={minDate ? moment(minDate) : null}
            maxDate={maxDate ? moment(maxDate) : null}
            {...props}
          />
        );
      case 'mobileTime':
        return (
          <MobileTimePicker
            onBlur={handleBlur}
            onChange={handleChange}
            closeOnSelect
            value={value ? moment(value) : null}
            format="hh:mm A"
            ampm
            slotProps={{
              textField: {
                fullWidth: true,
                size: props?.size ?? 'medium',
                InputProps: {
                  endAdornment: value ? (
                    <IconButton
                      aria-label="clear"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="calendar">
                      <AccessTimeIcon />
                    </IconButton>
                  )
                },
                error: Boolean(touchedValue && errorValue),
                helperText: touchedValue && errorValue
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept']
              }
            }}
            {...props}
          />
        );
      case 'time':
        return (
          <TextField
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            type="time"
            fullWidth
            {...props}
          />
        );
      case 'switch':
        return (
          <FormControlLabel
            className={classes.checkbox}
            control={(
              <Switch
                checked={value}
                onChange={handleChange}
                value={value}
                {...props}
              />
            )}
            label={formControlLabel}
          />
        );
      default:
        return (
          <TextField
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            fullWidth
            {...props}
          >
            {children}
          </TextField>
        );
    }
  };

  return (
    <TextFieldFormikContainer
      showInfo={showInfo}
      isShowInfoV2={isShowInfoV2}
      disabledBox={disabledBox}
      sizeInput={sizeInput}
      addHighlights={addHighlights}
      iconOnError={iconOnError}
      helpInfoMarginLeft={helpInfoMarginLeft}
      maxWidthSubtract={maxWidthSubtract}
      redirectURL={redirectURL}
      tooltipText={tooltipText}
      handleClickRedirect={handleClickRedirect}
    >
      {getFieldType()}
    </TextFieldFormikContainer>
  );
}

TextFieldFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  fieldType: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  touchedValue: PropTypes.any,
  errorValue: PropTypes.any,
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  isShowInfoV2: PropTypes.bool,
  children: PropTypes.node,
  disabledBox: PropTypes.bool,
  formControlLabel: PropTypes.string,
  sizeInput: PropTypes.oneOf([
    'default',
    'small',
  ]),
  addHighlights: PropTypes.oneOf([
    1,
    2,
  ]),
  iconOnError: PropTypes.oneOf([
    1,
  ]),
  helpInfoMarginLeft: PropTypes.oneOf([
    'default',
    '19',
    '5',
    '0',
  ]),
  maxWidthSubtract: PropTypes.number,
  redirectURL: PropTypes.string,
  tooltipText: PropTypes.string,
  handleClickRedirect: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  size: PropTypes.string,
};

TextFieldFormik.defaultProps = {
  fieldType: null,
  name: null,
  touchedValue: null,
  errorValue: null,
  value: '',
  showInfo: null,
  isShowInfoV2: false,
  children: null,
  disabledBox: false,
  formControlLabel: '',
  sizeInput: 'default',
  addHighlights: null,
  iconOnError: null,
  helpInfoMarginLeft: 'default',
  maxWidthSubtract: null,
  redirectURL: null,
  tooltipText: null,
  handleClickRedirect: null,
};

export default withStyles(TextFieldFormik, textFieldFormikStyle);
