/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CUSTOMERS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  SET_CUSTOMERS_QUERY,
  CLEAR_CUSTOMERS_QUERY_FILTER,
  GET_PURCHASED_PARTY_OPTIONS,
  GET_PURCHASED_PARTY_OPTIONS_SUCCESS,
  GET_PURCHASED_PARTY_OPTIONS_ERROR,
  SET_SELECTED_CUSTOMERS,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_SEND_TEXT,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_ERROR,
  SEND_TEXT_MESSAGE_SUCCESS,
  SET_VALUES_FORM_ADD_PB_TAG,
  SET_VALUES_FORM_ADD_TO_PARTY,
  SEND_ADD_PARTY_GUESTS,
  SEND_ADD_PARTY_GUESTS_ERROR,
  SEND_ADD_PARTY_GUESTS_SUCCESS,
  SEND_ADD_TAG_CAMPAIGN,
  SEND_ADD_TAG_CAMPAIGN_ERROR,
  SEND_ADD_TAG_CAMPAIGN_SUCCESS,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  GET_PARTIES_LIST,
  GET_PARTIES_LIST_SUCCESS,
  GET_PARTIES_LIST_ERROR,
  REPLACE_SYSTEM_GEN_MESSAGE,
  REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
  REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
  SET_BACK_TO_CUSTOMER_LIST,
  SET_BACK_TO_PARTY_GUEST_LIST,
  GET_PARTY_GUEST,
  GET_PARTY_GUEST_SUCCESS,
  GET_PARTY_GUEST_ERROR,
} from 'src/actions/customersActions';

const initialFilters = {
  type: null,
  typePurchase: null,
  purchasedParty: null,
  purchasedPartyList: [],
  typePurchaseDateFrom: null,
  typePurchaseDateTo: null,
  firstPurchaseDateFrom: null,
  firstPurchaseDateTo: null,
  lastPurchaseDateFrom: null,
  lastPurchaseDateTo: null,
  birthdayDayFrom: null,
  birthdayDayTo: null,
  birthdayMonthFrom: null,
  birthdayMonthTo: null,
  birthdayYearFrom: null,
  birthdayYearTo: null,
};

const formSendText = {
  systemGenMessageReplaced: false,
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const formAddPBTag = {
  loading: false,
};

const formAddToParty = {
  loading: false,
  party: '',
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const backToCustomerList = {
  customers: [],
  query: {},
  active: false,
  comeBack: false,
};

const backToPartyGuestList = {
  partyId: null,
  partyGuest: {},
  partyName: '',
  active: false,
  comeBack: false,
  backToId: null,
  startPartyGuestFromId: null,
  initScrollToId: false,
};

const initialState = {
  customers: [],
  selectedCustomers: [],
  query: {
    search: '',
    filters: initialFilters,
    sort: [],
    page: 1,
    pageSize: 50,
    count: 0,
  },
  purchasedPartyOptions: {
    data: [],
    loading: false,
  },
  parties: [],
  visibleModalFormNumber: null,
  formSendText,
  formAddPBTag,
  formAddToParty,
  followUpMessages,
  backToCustomerList,
  backToPartyGuestList,
  loading: false,
};

const customersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOMERS: {
      return produce(state, (draft) => {
        draft.customers = payload || [];
        draft.selectedCustomers = [];
      });
    }

    case GET_CUSTOMERS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_CUSTOMERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.customers = payload?.contacts || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedCustomers = [];
      });
    }

    case GET_CUSTOMERS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_CUSTOMERS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case CLEAR_CUSTOMERS_QUERY_FILTER: {
      return produce(state, (draft) => {
        draft.query.filters = initialFilters;
        draft.query.page = initialState.query.page;
      });
    }

    case GET_PURCHASED_PARTY_OPTIONS: {
      return produce(state, (draft) => {
        draft.purchasedPartyOptions.loading = true;
        draft.purchasedPartyOptions.data = [];
      });
    }

    case GET_PURCHASED_PARTY_OPTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.purchasedPartyOptions.loading = false;
        draft.purchasedPartyOptions.data = payload || [];
      });
    }

    case GET_PURCHASED_PARTY_OPTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.purchasedPartyOptions.loading = false;
      });
    }

    case SET_SELECTED_CUSTOMERS: {
      return produce(state, (draft) => {
        draft.selectedCustomers = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_SEND_TEXT: {
      return produce(state, (draft) => {
        draft.formSendText = payload ? {
          ...state.formSendText,
          ...payload,
        } : formSendText;
      });
    }

    case SEND_TEXT_MESSAGE: {
      return produce(state, (draft) => {
        draft.formSendText.loading = true;
      });
    }

    case SEND_TEXT_MESSAGE_SUCCESS:
    case SEND_TEXT_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.formSendText.loading = false;
      });
    }

    case SET_VALUES_FORM_ADD_PB_TAG: {
      return produce(state, (draft) => {
        draft.formAddPBTag = payload ? {
          ...state.formAddPBTag,
          ...payload,
        } : formAddPBTag;
      });
    }

    case SET_VALUES_FORM_ADD_TO_PARTY: {
      return produce(state, (draft) => {
        draft.formAddToParty = payload ? {
          ...state.formAddToParty,
          ...payload,
        } : formAddToParty;
      });
    }

    case SEND_ADD_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.formAddToParty.loading = true;
      });
    }

    case SEND_ADD_PARTY_GUESTS_SUCCESS:
    case SEND_ADD_PARTY_GUESTS_ERROR: {
      return produce(state, (draft) => {
        draft.formAddToParty.loading = false;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = true;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN_SUCCESS:
    case SEND_ADD_TAG_CAMPAIGN_ERROR: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = false;
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case GET_PARTIES_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTIES_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.parties = payload || {};
        draft.loading = false;
      });
    }

    case GET_PARTIES_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE: {
      return produce(state, (draft) => {
        draft.formSendText.loading = true;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS:
    case REPLACE_SYSTEM_GEN_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.formSendText.loading = false;
        draft.formSendText.systemGenMessageReplaced = payload || false;
      });
    }

    case SET_BACK_TO_CUSTOMER_LIST: {
      return produce(state, (draft) => {
        draft.backToCustomerList = payload ? {
          ...state.backToCustomerList,
          ...payload,
        } : backToCustomerList;
      });
    }

    case SET_BACK_TO_PARTY_GUEST_LIST: {
      return produce(state, (draft) => {
        draft.backToPartyGuestList = payload ? {
          ...state.backToPartyGuestList,
          ...payload,
        } : backToPartyGuestList;
      });
    }

    case GET_PARTY_GUEST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_GUEST_SUCCESS: {
      return produce(state, (draft) => {
        draft.backToPartyGuestList.partyGuest = payload?.partyGuest || {};
        draft.loading = false;
      });
    }

    case GET_PARTY_GUEST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default customersReducer;
