import { STYLE_OPTIONS } from 'src/constants';

const colorRed = '#ED3F3F';

const textFieldFormikStyle = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'start',

    '&.small .MuiInputBase-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '&.small .MuiFormControlLabel-root': {
      marginLeft: -8,

      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 400,
      },

      '& .MuiSvgIcon-root': {
        width: 17,
        height: 17,
      },
    },
  },

  textField: {
    width: '100%',

    '&.highlightsType1': {
      '& .MuiInputBase-root': {
        backgroundColor: STYLE_OPTIONS.COLOR_ACCOUNT_SETUP_FIELD_HIGHLIGHTS,
      },
    },

    '&.highlightsType2': {
      '& .MuiInputBase-root': {
        backgroundColor: '#ffffff',
      },
    },

    '&.iconOnErrorType1 p.Mui-error': {
      position: 'relative',
      marginLeft: '18px',
      '&:before': {
        position: 'absolute',
        top: '2px',
        left: '-18px',
        content: 'url(\'data:image/svg+xml;charset=utf-8,%3Csvg width%3D%2212%22 height%3D%2213%22 viewBox%3D%220 0 12 13%22 fill%3D%22none%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath fill%3D%22%23F15642%22 fill-rule%3D%22evenodd%22 clip-rule%3D%22evenodd%22 d%3D%22M6 12.5C9.31371 12.5 12 9.81371 12 6.5C12 3.18629 9.31371 0.5 6 0.5C2.68629 0.5 0 3.18629 0 6.5C0 9.81371 2.68629 12.5 6 12.5ZM6.5 4V7C6.5 7.27614 6.27614 7.5 6 7.5C5.72386 7.5 5.5 7.27614 5.5 7V4C5.5 3.72386 5.72386 3.5 6 3.5C6.27614 3.5 6.5 3.72386 6.5 4ZM6 10.1C6.27614 10.1 6.5 9.87615 6.5 9.60001C6.5 9.32386 6.27614 9.10001 6 9.10001C5.72386 9.10001 5.5 9.32386 5.5 9.60001C5.5 9.87615 5.72386 10.1 6 10.1Z%22 /%3E%3C/svg%3E\')',
      },
    },

    '& .MuiFormLabel-asterisk, & .MuiInputLabel-asterisk': {
      color: colorRed,
    },

    '&.disabledBox .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F4F4F4',
    },

    '&.disabledBox .MuiInputBase-input.Mui-disabled': {
      color: '#393939',
    },

    '&.disabledBox .MuiInputBase-root.Mui-disabled fieldset': {
      border: 0,
    },

    '& .MuiInputLabel-outlined': {
      maxWidth: 'calc(100% - 30px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '&.MuiInputLabel-shrink': {
        maxWidth: 'calc(100% + 20px)',
      }
    },

    '& .MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 0,
    },
  },

  checkbox: {
    marginTop: 6,

    '& .MuiTypography-body1': {
      marginLeft: 8,
    },
  },

  radio: {
    marginTop: 0,

    '& .MuiTypography-body1': {
      marginLeft: 8,
    },

    '& .Mui-checked + span': {
      color: STYLE_OPTIONS.COLOR_BLUE,
    },
  },

  info: {
    marginTop: 17,
  },

  redirect: {
    marginTop: 5,
    marginRight: -15,
  },
};

export default textFieldFormikStyle;
