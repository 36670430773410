import React from 'react';
import PropTypes from 'prop-types';
import TooltipMUI6 from 'src/components/TooltipMUI6';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpCircleRoot from 'src/components/HelpCircleMUI6/styled/HelpCircleRoot';

function HelpCircleMUI6({
  showInfo,
  marginLeft,
  sizeType,
}) {
  return showInfo && (
  <HelpCircleRoot
    className={`helpCircleBox sizeType-${sizeType} marginLeft-${marginLeft}`}
  >
    <TooltipMUI6
      title={showInfo}
    >
      <HelpOutlineIcon />
    </TooltipMUI6>
  </HelpCircleRoot>
  );
}

HelpCircleMUI6.propTypes = {
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  marginLeft: PropTypes.oneOf([
    'default',
    '19',
    '5',
    '0',
  ]),
  sizeType: PropTypes.oneOf([
    'default',
    '15',
  ]),
};

HelpCircleMUI6.defaultProps = {
  showInfo: false,
  marginLeft: 'default',
  sizeType: 'default',
};

export default HelpCircleMUI6;
