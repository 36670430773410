import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Unstable_Grid2';
import TextFieldFormik from 'src/components/TextFieldFormik/index';
import DefaultSelectOptions from 'src/components/TextFieldFormik/DefaultSelectOptions';
import helpers from 'src/helpers';
import TypographyContainer from 'src/components/TypographyContainer';
import changeCampaignsStyle from 'src/containers/ChangeCampaigns/changeCampaigns.style';

function CardChangeCampaigns({
  classes,
  formik,
  loading,
  campaignNamesToChange,
  currentCampaigns,
}) {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
  } = formik;

  const currentCampaignsPlusNone = currentCampaigns.length && currentCampaigns.length > 0 ? ['NO LONGER USE A PB CAMPAIGN FOR THESE SETTINGS'].concat(currentCampaigns) : [];

  const getInWhatSettingsList = (array) => array.map((item) => (
    <li key={item}>
      {item}
    </li>
  ));

  const pbCampaignsText1 = campaignNamesToChange.length === 1 ? 'PB campaign' : 'PB campaigns';
  const pbCampaignsText2 = campaignNamesToChange.length === 1 ? 'This campaign is used in CP settings.  So, we need to change those settings!' : 'These campaigns are used in CP settings.  So, we need to change those settings!';

  return (
    <div className={classes.cardBox}>
      <TypographyContainer>
        <div className={classes.windowText}>
          <p>
            <b>
              You have
              {' '}
              <font color="#FF0000">
                {campaignNamesToChange.length}
                {' '}
                {pbCampaignsText1}
              </font>
              {' '}
              that you deleted in PB recently.
              <br />
              <i>{pbCampaignsText2}</i>
            </b>
          </p>
        </div>
        {helpers.transform.toArray(campaignNamesToChange).map((campaignToChange, i) => {
          const {
            name,
            inWhatSettings,
          } = campaignToChange || {};
          return (
            <div
              key={name}
            >
              <hr
                className={classes.line}
              />
              <Grid
                spacing={0}
                container
              >
                <Grid xs={12}>
                  <div className={classes.windowText}>
                    <p>
                      <font color="#FF0000"><b>{name}</b></font>
                      {' '}
                      is currently used in these CP settings:
                    </p>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className={classes.ulPurple}>
                    <ul>
                      {getInWhatSettingsList(inWhatSettings)}
                    </ul>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className={classes.windowText}>
                    <p>
                      Please choose another campaign that you would like to use in it's place for these CP settings.
                    </p>
                    <center>
                      <b>--OR--</b>
                    </center>
                    <p>
                      Select the first option - NO LONGER USE A PB CAMPAIGN FOR THESE SETTINGS!
                    </p>
                  </div>
                </Grid>
                <Grid xs={12}>
                  {(currentCampaigns.length && currentCampaigns.length > 0 && (
                    <div className={classes.selection}>
                      <TextFieldFormik
                        touchedValue={touched[`campaign${[i]}`]}
                        errorValue={errors[`campaign${[i]}`]}
                        name={`campaign${[i]}`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values[`campaign${[i]}`]}
                        label={`Campaign to Replace '${name}'`}
                        disabled={loading}
                        SelectProps={{ native: true }}
                        required
                        select
                      >
                        <DefaultSelectOptions
                          value={values[`campaign${[i]}`]}
                          values={currentCampaignsPlusNone}
                          addNullAnyway={false}
                          isNullValueDisabled
                        />
                      </TextFieldFormik>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </TypographyContainer>
    </div>
  );
}

CardChangeCampaigns.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  campaignNamesToChange: PropTypes.array.isRequired,
  currentCampaigns: PropTypes.array.isRequired,
};

// export default CardChangeCampaigns;
export default withStyles(CardChangeCampaigns, changeCampaignsStyle);
