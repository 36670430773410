/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_PARTY_TYPES,
  GET_PARTY_TYPES,
  GET_PARTY_TYPES_SUCCESS,
  GET_PARTY_TYPES_ERROR,
  SET_PARTY_TYPES_QUERY,
  SET_BACK_TO_PARTY_TYPES_LIST,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_APPLY_SHARE_CODE,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_ERROR,
  APPLY_SHARE_CODE,
  APPLY_SHARE_CODE_SUCCESS,
  APPLY_SHARE_CODE_ERROR,
  SET_IS_MODAL_VISIBLE,
  SET_IS_MODAL_LOADING,
  SET_MODAL_MESSAGE,
} from 'src/actions/partyTypesActions';

const backToPartyTypesList = {
  partyTypes: [],
  query: {},
  active: false,
  comeBack: false,
};

const formApplyShareCode = {
  loading: false,
  shareCode: '',
  validCode: true,
  partyTypeOwnerFirstName: '',
  partyTypeOwnerLastName: '',
  partyTypeName: '',
};

const modalMessage = {
  isVisible: false,
  modalTitle: null,
  modalBody: '',
};

const initialState = {
  partyTypes: [],
  query: {
    status: 'Active',
  },
  visibleModalFormNumber: null,
  backToPartyTypesList,
  loading: false,
  formApplyShareCode,
  modalMessage,
  isModalVisible: false,
  isModalLoading: false,
};

const partyTypesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.partyTypes = payload || [];
      });
    }

    case GET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_TYPES_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyTypes = payload?.partyTypes || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
      });
    }

    case GET_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_TYPES_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_BACK_TO_PARTY_TYPES_LIST: {
      return produce(state, (draft) => {
        draft.backToPartyTypesList = payload ? {
          ...state.backToPartyTypesList,
          ...payload,
        } : backToPartyTypesList;
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case VERIFY_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = true;
      });
    }

    case VERIFY_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.validCode = true;
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.partyTypeOwnerFirstName = payload?.partyTypeOwnerFirstName || [];
        draft.formApplyShareCode.partyTypeOwnerLastName = payload?.partyTypeOwnerLastName || [];
        draft.formApplyShareCode.partyTypeName = payload?.partyTypeName || [];
      });
    }

    case VERIFY_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.validCode = false;
      });
    }

    case SET_VALUES_FORM_APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode = payload ? {
          ...state.formApplyShareCode,
          ...payload,
        } : formApplyShareCode;
      });
    }

    case APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case APPLY_SHARE_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case APPLY_SHARE_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_MODAL_MESSAGE: {
      return produce(state, (draft) => {
        draft.modalMessage = {
          ...state.modalMessage,
          ...payload,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default partyTypesReducer;
