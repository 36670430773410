import { BlobServiceClient } from '@azure/storage-blob';
import moment from 'moment';
import { STORAGE } from 'src/constants';
import { isEmpty } from 'src/helpers/check';

export const createFileName = (fileName, uniqueFileSlug = false) => (`${uniqueFileSlug ? `${uniqueFileSlug}-` : ''}${moment().format('YYYY-MM-DD-HH-mm-ss-SS')}-${fileName.replace(/[^a-z0-9.-]/gi, '')}`);

export const findUniqueFileSlug = (fileName, uniqueFileSlug = false) => {
  if (!uniqueFileSlug) {
    return false;
  }
  return !!fileName.match(new RegExp(`^${uniqueFileSlug}-`));
};

export const validateFileSize = (newFileUpload) => {
  const { file } = newFileUpload || {};
  // return !file || file?.size < 1500000;
  return !file || file?.size < 1450000;
};

export const validateFileType = (newFileUpload) => {
  try {
    const {
      file,
    } = newFileUpload || {};
    const {
      name,
      type,
    } = file || {};

    const types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
    const fileExtension = ['.png', '.jpg', '.jpeg', '.gif'];

    const isCorrectFileExtension = name && fileExtension.find((extension) => `${name}`.toLowerCase().endsWith(extension));
    const isCorrectFiletype = type && types.find((item) => item === type);

    return !file || (isCorrectFileExtension && isCorrectFiletype);
  } catch (e) {
    return false;
  }
};

export const getUserContainerName = (databaseBeingUsed, $userId = 0) => {
  const { container } = STORAGE;
  return `${databaseBeingUsed || ''}${container}${$userId || 0}`;
};

export const isUserContainer = (databaseBeingUsed, userId, container) => {
  // If contaner = null, defaults to user container.
  if (!container) {
    return true;
  }
  const containerName = getUserContainerName(databaseBeingUsed, userId);
  return containerName === container;
};

export const getContainerNameAndFileNameFromFileURL = (imageFilePath) => {
  const fileOptions = imageFilePath.match(/(.*)\/(.*?)\/(.*?)$/);
  const fileName = fileOptions && !isEmpty(fileOptions[3]) ? fileOptions[3] : false;
  const containerName = fileOptions && !isEmpty(fileOptions[2]) ? fileOptions[2] : false;

  return containerName && fileName ? {
    containerName,
    fileName
  } : false;
};

export const uploadFile = async (file, newFileName, userId, account, sas, databaseBeingUsed, container) => {
  try {
    const containerName = container || getUserContainerName(databaseBeingUsed, userId);
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const isContainerExists = await containerClient.exists();
    if (!isContainerExists) {
      await containerClient.create();
    }
    const blockBlobClient = containerClient.getBlockBlobClient(newFileName);
    return await blockBlobClient.upload(file, file.size);
  } catch (e) {
    return false;
  }
};

export const deleteFile = async (imageFilePath, account, sas) => {
  try {
    const { containerName, fileName } = getContainerNameAndFileNameFromFileURL(imageFilePath) || {};
    if (!containerName || !fileName) {
      return false;
    }
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    return await blockBlobClient.deleteIfExists(imageFilePath);
  } catch (e) {
    return false;
  }
};

export const addSasToUrl = (url, sas) => (`${url}${sas}`);

export const getImageFileURL = (name, userId, account, sas, databaseBeingUsed, container, addSas = false) => {
  if (isEmpty(name)) {
    return name;
  }
  const containerName = container || getUserContainerName(databaseBeingUsed, userId);
  const url = !name.match(/^http(s?):\/\//i) ? `https://${account}.blob.core.windows.net/${containerName}/${name}` : name;
  return addSas ? addSasToUrl(url, sas) : url;
};

export const loadFileToAzureAndGetStatusCode = async (fileUpload, attachmentStr, userId, account, sas, databaseBeingUsed, container, fileWasNotUpload, fileWasNotDelete, doYouNeedFullUrl = false, uniqueFileSlug = false, allowDeleteFile = true) => {
  // NOTE: Eventually we should allow here to delete a file any time.  But, it would need to call an endpoint on the backend to see if any other orders (or anything else) references the image first.
  const newAttachmentStr = !attachmentStr && attachmentStr !== false ? '' : attachmentStr;
  const newFileName = fileUpload ? createFileName(fileUpload?.file?.name || '.jpg', uniqueFileSlug) : '';
  if (fileUpload) {
    const wasFileUploaded = await uploadFile(fileUpload?.file, newFileName, userId, account, sas, databaseBeingUsed, container);
    if (!wasFileUploaded) {
      fileWasNotUpload();
      return null;
    }
  }
  const isFileDeleted = (fileUpload === null || fileUpload) && !isEmpty(attachmentStr);
  if (allowDeleteFile && isFileDeleted && isUserContainer(databaseBeingUsed, userId, container) && (!uniqueFileSlug || findUniqueFileSlug(attachmentStr, uniqueFileSlug))) {
    const wasFileDeleted = await deleteFile(getImageFileURL(newAttachmentStr, userId, account, sas, databaseBeingUsed, container), account, sas);
    if (!wasFileDeleted) {
      fileWasNotDelete();
      return null;
    }
  }
  const newValuesAttachment = isFileDeleted ? '' : newAttachmentStr;

  if (doYouNeedFullUrl) {
    return fileUpload ? getImageFileURL(newFileName, userId, account, sas, databaseBeingUsed, container) : newValuesAttachment;
  }

  return fileUpload ? newFileName : newValuesAttachment;
};
